import React, { Component } from 'react';
import { Link } from "react-router-dom"
import './Home.css'
class Home  extends Component {
render() {
    return (
        <div class = 'home-container'>
          <h1> FSMA Content Management System </h1>
          <br />
          <h2>What do you want to edit? </h2>
          <div class = 'box-container'>
             <div class = 'box'>
              <h4> Home Page </h4>
              <Link to="/cms/addnews">Add Latest News </Link><br />
              <Link to="/cms/managenews">Manage News & Archive </Link><br />
              <Link to="/cms/special">Edit Special Appreciation </Link><br/>
              <Link to="/cms/donor">Edit Donor's Names </Link>
             </div>
             <div class = 'box'>
              <h4> About FSMA </h4>
              <Link to="/cms/patient">Change Patient Forms </Link><br />
              <Link to="/cms/manageappreciation">Manage Notes of Appreciation </Link><br />
              <Link to="/cms/orgstructure">Edit FSMA’s Organization Structure </Link><br/>
             </div>
             <div class = 'box'>
             <h4> Get Involved </h4>
                <Link to="/cms/volunteer">Change Volunteer Forms </Link><br />
                <Link to="/cms/managecharityitems">Manage Charity Items </Link><br />
             </div>
         </div>
       </div>
    );
  }
}

export default Home;