import React, {useState, useEffect, useRef} from 'react';
import { Link, useLocation, useHistory } from "react-router-dom"
import { CloudinaryContext, Image } from "cloudinary-react";
import './EditNews.css'
import {openUploadWidget } from "../CloudinaryService";
import Confirmation from '../components/Confirmation.js'
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditNews = () => {
  const [show, setShow] = useState(false)
  const [news, setNews] = useState({
    coverId:'',
    category:'',
    eng:{
      header:'',
      content:'',
      photoId:'',
      photoArray:[],
      video:'',
      testing:''
    },
    tra:{
      header:'',
      content:'',
      photoId:'',
      photoArray:[],
      video:''
    },
  sim:{
      header:'',
      content:'',
      photoId:'',
      photoArray:[],
      video:''
    }
  })
  const [video,setVideo] = useState(false)
  const [coverId, setCoverId] = useState('')
  let data = useLocation();
  let history = useHistory();
  useEffect(() => {

    const callApi = async () => {

      const response = await fetch(`/api/getNews?news_id=${encodeURIComponent(data.state._id)}`, {
        method: "GET"
      })
      const body = await response.json();
      if (response.status !== 200) throw Error(body.message);

      return body;
    };
    callApi()
      .then(res => 
        {
          const newsBody = res.news;
          console.log(newsBody)
          setNews(newsBody[0])
        })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {

    if (typeof data.state == 'undefined'){
      history.push('/managenews')
    }
  },[data])

  useEffect(() =>{
    console.log('onmount')
    if(news.eng.photoArray===undefined)
    {
      setNews({
        ...news,
        eng:{
          ...news.eng,
          photoArray:[]
        }
      })
    }
   if(news.tra.photoArray===undefined)
    {
      setNews({
        ...news,
        tra:{
          ...news.tra,
          photoArray:[]
        }
      })
    }

    if(news.sim.photoArray===undefined)
    {
      setNews({
        ...news,
        sim:{
          ...news.sim,
          photoArray:[]
        }
      })
    }
    console.log(news)

  },[news])

  useEffect(()=>{
    console.log(news)
  },[news])

 const handleChange = (event) =>{
    if([event.target.id] == 'eng'){
      setNews({
        ...news,
        eng:{
          ...news.eng,
          [event.target.name]:event.target.value
        }
      })
  }
  if([event.target.id] == 'tra'){
      setNews({
        ...news,
        tra:{
          ...news.tra,
          [event.target.name]:event.target.value
        }
      })
  }
  if([event.target.id] == 'sim'){
      setNews({
        ...news,
        sim:{
          ...news.sim,
          [event.target.name]:event.target.value
        }
      })
  }
  console.log(news)
  }

  const handleSelect=(e)=>{
    setNews({
      ...news,
      category:e
    })
    console.log(news)
  }

  const handleSubmit = async public_id => {
    console.log(news)
    const response = await fetch('/api/editNews', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({news:news }),
    })
    const body = await response.text().then(res => console.log(res));
    setShow(true);
    const timerId = setInterval(() => {
      setShow(false)
    }, 2000);
  };

  const deletePhotos = (lang) =>{
      if(lang === 'eng'){
              setNews(news=>({
                ...news,
                eng:{
                  ...news.eng,
                  photoArray:[],
                }
              }))
            }
            if(lang === 'sim'){
              setNews({
                ...news,
                sim:{
                  ...news.sim,
                  photoArray:[]
                }
              })
            }
            if(lang === 'tra'){
              setNews({
                ...news,
                tra:{
                  ...news.tra,
                  photoArray:[]
                }
              })
            }
  }

  const beginUpload = (tag,lang) => {
    const uploadOptions = {
      cloudName: "dn5v5udu6",
      tag: tag,
      uploadPreset: "upload"
    };


    openUploadWidget(uploadOptions, (error, photos) => {
      if (!error) {

        if(photos.event === 'success'){
          if(tag == 'cover'){
            setNews({
              ...news,
              coverId:photos.info.public_id
            })
          }
          if(tag === 'photo'){
            if(lang === 'eng'){
              console.log('itworkssss')
              setNews({
                ...news,
                eng:{
                  ...news.eng,
                  photoId:photos.info.public_id
                }
              })
            }
            if(lang === 'sim'){
              setNews({
                ...news,
                sim:{
                  ...news.sim,
                  photoId:photos.info.public_id
                }
              })
            }
            if(lang === 'tra'){
              setNews({
                ...news,
                tra:{
                  ...news.tra,
                  photoId:photos.info.public_id
                }
              })
            }
          }
          if(tag === 'mass'){
            if(lang === 'eng'){
                setNews(news=>({
                  ...news,
                  eng:{
                    ...news.eng,
                    photoArray:[...news.eng.photoArray,photos.info.public_id],
                  }
                }))
            }
            if(lang === 'sim'){
                setNews(news=>({
                    ...news,
                    sim:{
                      ...news.sim,
                      photoArray:[...news.sim.photoArray,photos.info.public_id],
                    }
                  }))
            }
            if(lang === 'tra'){
              setNews(news=>({
                  ...news,
                  tra:{
                    ...news.tra,
                    photoArray:[...news.tra.photoArray,photos.info.public_id],
                  }
                }))
            }
          }

        }
      } else {
        console.log(error);
      }
    })
  }

  const handleEngQuillEdit = (value) => {
    setNews((news) => {
              return {
                ...news,
                  eng:{
                    ...news.eng,
                    content:value
                  }
            }
        })

  }

  const handleTraQuillEdit = (value) => {
    setNews((news) => {
              return {
                ...news,
                  tra:{
                    ...news.tra,
                    content:value
                  }
            }
        })

  }

  const handleSimQuillEdit = (value) => {
    setNews((news) => {
              return {
                ...news,
                  sim:{
                    ...news.sim,
                    content:value
                  }
            }
        })

  }
  return (
    <CloudinaryContext cloudName="dn5v5udu6">
      <Confirmation show = {show} sentence = 'News Uploaded'/>
      <div class = 'news-container'>
          <Link to="/cms" class = 'home-link'> Back Home </Link>
            <h2> Edit News </h2>
          <div class = 'news-subcontainer'>
          <div>
            <div class = 'news-box'>
              <div class = 'language-box'>
                <b>English version</b>

                <p> Header</p>

                      <textarea id = 'eng' name = 'header' class = 'header-ta' placeholder='Give a succinct and engaging header for the news' value={news.eng.header} onChange={handleChange} />                
                    <p> Content</p>
                    <div class = 'an-links'>
                      <a href="javascript:void(0)" onClick = {() => beginUpload('photo','eng')}> Insert photo </a>
                      <a href="javascript:void(0)" onClick = {() => beginUpload('mass','eng')}> Insert more photo </a>
                      <a href="javascript:void(0)" onClick = {() => deletePhotos('eng')}> Delete more photos </a>
                      <a href="javascript:void(0)" onClick = {() =>  setVideo(true, 'eng')}> Insert video link </a>
                    </div>    
                    {video &&
                      <textarea id = 'eng' name = 'video' class = 'header-ta' placeholder='Put in your youtube link' value={news.eng.video} onChange={handleChange} /> 
                    }

                     <ReactQuill   
                      onChange={ handleEngQuillEdit }
                      value={news.eng.content}              
                     />      
                  
              </div>
                    {news.eng.photoId==""
                    ?<div></div>
                    :<div style = {{marginBottom:"5%",marginTop:"5%"}}>
                      <h2> Picture </h2>
                      <Image publicId={news.eng.photoId}></Image>
                    </div>
                  }
                  {news.eng.photoArray == undefined || news.eng.photoArray.length===0
                    ?<div></div>
                    :<div style = {{marginBottom:"5%",marginTop:"5%"}}>
                      <h2> English More Pictures </h2>
                      {news.eng.photoArray.map(function (item, index) {
                            return <Image publicId={item}></Image>
                        })}
                    </div>
                  }
  
            </div>
            <div class = 'news-box'>
              <div class = 'language-box'>
                <b>Traditional Chinese version</b>

                <p> Header</p>

                      <textarea id = 'tra' name = 'header' class = 'header-ta' placeholder='Give a succinct and engaging header for the news' value={news.tra.header} onChange={handleChange} />                
                    <p> Content</p>
                    <div class = 'an-links'>
                      <a href="javascript:void(0)" onClick = {() => beginUpload('photo','tra')}> Insert photo </a>
                      <a href="javascript:void(0)" onClick = {() => beginUpload('mass','tra')}> Insert more photo </a>
                      <a href="javascript:void(0)" onClick = {() => deletePhotos('tra')}> Delete more photos </a>
 
                      <a href="javascript:void(0)" onClick = {() =>  setVideo(true, 'tra')}> Insert video link </a>
                    </div>    
                    {video &&
                      <textarea id = 'tra' name = 'video' class = 'header-ta' placeholder='Put in your youtube link' value={news.tra.video} onChange={handleChange} /> 
                    }

                     <ReactQuill   
                      onChange={ handleTraQuillEdit }
                      value={news.tra.content}              
                     />                    
                </div>
                {news.tra.photoId==""
                    ?<div></div>
                    :<div style = {{marginBottom:"5%",marginTop:"5%"}}>
                      <h2> Picture </h2>
                      <Image publicId={news.tra.photoId}></Image>
                    </div>
                  }
                {news.tra.photoArray == undefined || news.tra.photoArray.length===0
                    ?<div></div>
                    :<div style = {{marginBottom:"5%",marginTop:"5%"}}>
                      <h2> Traditional Chinese More Pictures </h2>
                      {news.tra.photoArray.map(function (item, index) {
                            return <Image publicId={item}></Image>
                        })}
                    </div>
                  }
  
            </div>
            <div class = 'news-box'>
              <div class = 'language-box'>
                <b>Simplified Chinese version</b>

                <p> Header</p>

                      <textarea id = 'sim' name = 'header' class = 'header-ta' placeholder='Give a succinct and engaging header for the news' value={news.sim.header} onChange={handleChange} />                
                    <p> Content</p>
                    <div class = 'an-links'>
                      <a href="javascript:void(0)" onClick = {() => beginUpload('photo','sim')}> Insert photo </a>
                      <a href="javascript:void(0)" onClick = {() => beginUpload('mass','sim')}> Insert more photo </a>
                      <a href="javascript:void(0)" onClick = {() => deletePhotos('sim')}> Delete more photos </a>
 
                      <a href="javascript:void(0)" onClick = {() =>  setVideo(true)}> Insert video link </a>
                    </div>    
                    {video &&
                      <textarea id = 'sim' name = 'video' class = 'header-ta' placeholder='Put in your youtube link' value={news.sim.video} onChange={handleChange} /> 
                    }

                    <ReactQuill   
                      onChange={ handleSimQuillEdit }
                      value={news.sim.content}              
                     />      
                  
              </div>
                  {news.sim.photoId==""
                    ?<div></div>
                    :<div style = {{marginBottom:"5%",marginTop:"5%"}}>
                      <h2> Picture </h2>
                      <Image publicId={news.sim.photoId}></Image>
                    </div>
                  }
                  {news.sim.photoArray == undefined || news.sim.photoArray.length===0
                    ?<div></div>
                    :<div style = {{marginBottom:"5%",marginTop:"5%"}}>
                      <h2> Simpified Chinese More Pictures </h2>
                      {news.sim.photoArray.map(function (item, index) {
                            return <Image publicId={item}></Image>
                        })}
                    </div>
                  }
            </div>
        <button onClick = {handleSubmit}>submit</button>
          </div>
            <div>
              <div class = 'cover-box'>
                <b>Cover Picture</b>
                        <button onClick={() => beginUpload('cover')} class= 'image-button'>Upload Image</button>
                <p> A 4:3 ratio photo is preferred for the cover photo.</p>
                <p> Current Cover Picture</p>
                <div className="image-box">
                      <Image publicId={news.coverId}></Image>


                   </div>
              </div>
              <div class = 'an-dropdown'>
                Choose News Categories


            <ToggleButtonGroup className = 'an-checkbox' type="checkbox" title="Choose A Category" onChange={handleSelect}>
                <ToggleButton size="sm" variant="secondary" value="New Drug Information">New Drug Information</ToggleButton>
                <ToggleButton size="sm" variant="secondary" value="History and Track Record">History and Track Record</ToggleButton>
                <ToggleButton size="sm" variant="secondary" value="Public Awareness">Public Awareness</ToggleButton>
                <ToggleButton size="sm" variant="secondary" value="Member Activities">Member Activities</ToggleButton>
                <ToggleButton size="sm" variant="secondary" value="Fund-raising Activities">Fund-raising Activities</ToggleButton>
              </ToggleButtonGroup>
          </div>
            </div>
          </div>
      </div>
    </CloudinaryContext>
  );
}
export default EditNews