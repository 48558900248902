import React, { useState, useEffect, useRef } from 'react'
import './Involved.css'
import Donation from './components/Donation.js'
import { CloudinaryContext, Image } from "cloudinary-react";
import Header from './Header.js'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
import Footer from './Footer.js'
import Contact from './components/Contact.js'
import { translate,getLanguage } from 'react-switch-lang';
import { Link } from "react-router-dom"
import useOutsideClick from "./components/useOutsideClick";
init("user_sRmLw1359QUtu8osFqeaz");
const Involved = (props) => {
	const { t } = props;
	let ReactSafeHtml = require('react-safe-html');
	const [forms,setForms] = useState({
		individual:'',
		organization:'',
		volunteerArr:[
			{
			pdfId:'',
			originalFilename:'',
			lang:'eng'
			},
			{
			pdfId:'',
			originalFilename:'',
			lang:'tra'
			},
			{
			pdfId:'',
			originalFilename:'',
			lang:'sim'
			}]
		})

	  const [charityItems, setCharityItems] = useState([{
	  	coverId:['','','',''],
	  	price:'',
	  	order:'',
	  	eng:{
	  		name:'',
	  		tag:'',
	  		desc:'',
	  	},
	  	tra:{
	  		name:'',
	  		tag:'',
	  		desc:''
	  	},
	  	sim:{
	  		name:'',
	  		tag:'',
	  		desc:''
	  	}

	  }])
	 useEffect(() => {
		const callForms = async () => {
	   	    const response = await fetch('/api/volunteerForms');
		    const body = await response.json();
		    if (response.status !== 200) throw Error(body.message);

		    return body;
	    }
	    const callCharityItems = async () => {
	    	const response = await fetch ('/api/charityItems');
	    	const body = await response.json();
		    if (response.status !== 200) throw Error(body.message);

		    return body;
	    }

	    callCharityItems()
	    	.then (res =>
	    	{
	    		setCharityItems(res.items)
	    	})
	    	.catch(err => console.log(err));
	    callForms()
	      .then(res => 
	        {
	          setForms(res.forms[0])
	          console.log(res.forms) 	
	        })
	      .catch(err => console.log(err));
	    
	  }, []); 

	function CharityItems(props) {
		const [item,setItem] = useState({
	  	coverId:['','','',''],
	  	price:'',
	  	order:'',
	  	eng:{
	  		name:'',
	  		tag:'',
	  		desc:'',
	  	},
	  	tra:{
	  		name:'',
	  		tag:'',
	  		desc:''
	  	},
	  	sim:{
	  		name:'',
	  		tag:'',
	  		desc:''
	  	}

	  })

 		function CharityItem(props){
 			const ref = useRef();
 			const [item, setItem] = useState(props.item)
 			const [showItem,setShowItem] = useState(false)
 			const [sentEmail,setSentEmail] = useState(false)
			const [interested,setInterested] = useState(false)
			const [photo,setPhoto] =useState(0)
			const [person, setPerson] = useState({
				name:'',
				telephone:'',
				email:'',
				detail:''
			})
			  useOutsideClick(ref, () => {
			    setShowItem(!showItem)
			  });
			const showImages = item.coverId.map((image,idx) => 
 			<div>
 				<Image class = 'pointer' publicId={image} onClick = {()=> setPhoto(idx)}></Image>
 			</div>
 			)

		   const handleChange = (event) =>{
		  	setPerson({ 
		  		...person,
		  		[event.target.name]:event.target.value
		  		})
		  	console.log(person)
		  }
		  const sendEmail = () =>{
		  	console.log('sending email')
		  	console.log(person.name)
		  	emailjs.send("service_53h0kza","template_nl7zqx8",
		  		{
		  			name:person.name,
		  			email:person.email,
		  			telephone:person.telephone,
		  			detail:person.detail,
		  			product:item.eng.name,
		  			price:item.price


		  		}).then((response) => {
			       console.log('SUCCESS!', response.status, response.text);
			       setSentEmail(true)
			    }, (err) => {
			       console.log('FAILED...', err);
			    });
		  }
	 		const closeItem = () => {
				setShowItem(false)
				setSentEmail(false)
				setInterested(false)

			}
 			const changeItem = (item,idx) => {
				setShowItem(true)
			}

 			return(
 				<div>
	 			 	<div class = 'pointer charity-item' onClick ={()=>changeItem(item, props.idx)}>
	 			 		<Image className = 'charity-item-pic' publicId={item.coverId[0]}></Image>
	 					<div class = 'charity-item-title'><b>{item[getLanguage()].name} </b></div>
	 					<div class ='charity-item-tag-row'>
	 						<div class = 'charity-item-tag'>
	 							{item[getLanguage()].tag}
	 						</div>
	 						{item.price}
	 					</div>
	 				</div>	
	 				{showItem&&
	 					<div class = 'charity-item-container-mobile' ref={ref}>
			 				<div></div>
			 				<div class ='close-item-container'>
			 				<img src = 'x.png' class = 'close-item pointer' onClick={()=>closeItem(false)} /> 
			 				</div>
					          <div class = 'charity-item-container-image'>
					          		<Image publicId={item.coverId[photo]} class = 'charity-item-main-image'></Image>
					          		<div class = 'small-image-row'>
					          		{showImages}
					          		</div>
					          	</div>
					          		<div class = 'charity-item-text'>
					          			<div>
						          			<h4>{item[getLanguage()].name}</h4>
						          			<p>{item.price}</p>
					          			</div>
					          			{interested
					          				?<div> 
					          					{!sentEmail
					          					?<div>
						          					<p class = 'pointer back-text' onClick={()=>setInterested(false)}><img src='./back-arrow.png' class = 'charity-back-arrow' />Back</p>
						          					<p> {t('involved.fourthText1')}</p>
						          					<div class = 'charity-item-contact-container'>
						          						<div class = 'charity-item-contact-first-row'>
						          							<div>
						          								 {t('involved.fourthText2')}
						          								<input placeholder =  {t('involved.fourthSampleName')}name = 'name' value={person.name} onChange={handleChange} />
						          							</div>
						          							<div>
						          								 {t('involved.fourthText3')}
						          								<input placeholder = '(+000) 0000 0000' name = 'telephone' value={person.telephone} onChange={handleChange} />
						          							</div>
						          						</div>
					          							<div>
					          								<div class = 'email-row'>
					          								 {t('involved.fourthText4')}
					          								<input placeholder = 'email123@gmail.com' name = 'email' value={person.email} onChange={handleChange} />
					          								</div>
					          								<div class = 'detail-row'>
					          								 {t('involved.fourthText5')}
					          								<input name = 'detail' value={person.detail} onChange={handleChange} />
					          								</div>
					          							</div>
						          							<div class = 'charity-button-row'>
						          								<Button variant="charity" class='charity-interested-button' onClick={sendEmail}> {t('involved.fourthText6')}</Button>
						          							</div>
							          					</div>
						          				 </div>
						          				 :<div>
						          				 	<div class = 'thank-you-text'> {t('involved.fourthText7')}</div>
							          				  <div class = 'charity-button-row'>
							          				  	<Button variant="charity2" class='charity-interested-button' onClick={()=>setShowItem(false)}> {t('involved.fourthText8')}</Button>
							          				  </div>
						          				  </div>
												}
											</div>
					          				:<div>
					          					<div class = 'charity-item-desc'>
								          			<ReactSafeHtml html={item[getLanguage()].desc} />
								          		</div>
								          		<div class = 'charity-button-row'>
								          			<Button variant="charity" class='charity-interested-button' onClick={()=>setInterested(true)}> {t('involved.fourthText9')}</Button>
					          					</div>
					          				</div>
					          			}
					          		</div>
					          </div>	
					         }

	 			</div>
 			)
 		}

 		const displayItems = charityItems.map((item,idx) => {
 			return(
		 			<div >
		 				<CharityItem item = {item} idx = {idx} />
		 			</div>
		 		)
		 	})

 		return (
 			<div class = 'charity-items-row'>
 				{displayItems}
 			</div>
 		)

 	}


 
	return (
	    <CloudinaryContext cloudName="dn5v5udu6">
	    <Header></Header>
		<div class = 'involved-color'>
			<div class = 'involved'>
				<div class = 'involved-words'>
					<h1> {t('involved.title')}</h1>
					<p>{t('involved.mainText1')} <br/>{t('involved.mainText2')} <br/>{t('involved.mainText3')}</p> 
					<div> <Link to="/privacy" activeClassName="active-link">{t('pics')}</Link> </div>
				</div>

			</div>
		</div>
		<div class = 'involved-first-second'>
			<img src= './wave3.png' class='involved-second-wave'></img>
		</div>
		<div class = 'involved-second'>
			<h1> {t("involved.secondTitle")} </h1>
			<div class = 'volunteer-sub'>
				<div class = 'volunteer-container'>
					<div class = 'volunteer-dots-container'>
						<img src= './about-dots.png' class='volunteer-dots'></img>
					</div>
					<div class = 'volunteer-words'>
					<p>{t("involved.secondText1")} </p>
					<p>{t("involved.secondText2")} </p>
					</div>
					<div class = 'volunteer-form-row'>
						<img src= './app.png' class='involved-app'></img>
						<a href = {forms.individual}> {t("involved.secondIndividualForm")} <img src= './black_arrow.png' class='black-arrow' /></a>
					</div>
					<div class = 'volunteer-form-row'>
						<img src= './app.png' class='involved-app'></img>
						<a href = {forms.organization}> {t("involved.secondOrgForm")} <img src= './black_arrow.png' class='black-arrow' /></a>
					</div>
					<div class = 'volunteer-form-row mail-row'>
						<img src= './mail.png' class='involved-mail'></img>
						<p>{t("involved.secondText3")}
						{console.log(forms)}
						{getLanguage()=='eng'
							?<a href = {'https://res.cloudinary.com/dn5v5udu6/image/upload/fl_attachment/'+forms.volunteerArr[0].pdfId+'.pdf'}>{t("involved.secondText4")} </a>
							:<a href = {'https://res.cloudinary.com/dn5v5udu6/image/upload/fl_attachment/'+forms.volunteerArr[1].pdfId+'.pdf'}>{t("involved.secondText4")} </a>
						}
						{t("involved.secondText5")}:</p>
						<div />
						<p>
						{t("contact.fax")}: 2510 7199 <br /> <br />
						{t("contact.email")}: info@fsma.org.hk <br /> <br />
						{t("contact.address")}: {t("contact.address1")}<br /> {t("contact.address2")}
						</p>
					</div>
				</div>
				<div class = 'involved-second-pic'>
					<img src= './involved-second.png' class = 'involved-second-image'></img>
					<img src= './involved-second-mobile.png' class = 'involved-second-mobile-image'></img>
				</div>
			</div>
		</div>
		<div class = 'involved-fourth'>
			<div class = 'involved-fourth-sub'>
				<div class = 'involved-fourth-title'>
					<h1>{t("involved.fourthTitle")}</h1>
					<div class = 'involved-fourth-text'>
						<p>{t("involved.fourthText")}   </p>
					</div>
					<CharityItems />
				</div>
			</div>
		</div>
				<Contact color='yellow'/>
				<Footer />
	</CloudinaryContext>
	);
}

export default  translate(Involved);
