import React, { Component, useState } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import Home from './Home.js';
import Privacy from './Privacy.js'
import Login from './Login.js'
import ProtectedRoute from './ProtectedRoute';
import Unauthorized from './Unauthorized';
import Donor from './index/Donor.js'
import Special from './index/Special.js'
import AddNews from './index/AddNews.js'
import ManageNews from './index/ManageNews.js'
import EditNews from './index/EditNews.js'
import Patient from './about/Patient.js'
import ManageAppreciation from './about/ManageAppreciation.js'
import NewAppreciation from './about/NewAppreciation.js'
import EditAppreciation from './about/EditAppreciation.js'
import OrgStructure from './about/OrgStructure.js'
import UpdateChart from './about/UpdateChart.js'
import Volunteer from './involved/Volunteer.js'
import ManageCharityItems from './involved/ManageCharityItems.js'
import EditCharityItem from './involved/EditCharityItem.js'
import AddCharityItem from './involved/AddCharityItem.js'
import logo from './logo.svg';
import Main from './Main.js';
import About from './About.js';
import Involved from './Involved.js';
import Archive from './Archive.js';
import ArchiveItem from './ArchiveItem.js';
import Contact from './Contact.js';
import Donate from './Donate.js';
import SMA from './SMA.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import eng from './translation/eng.json';
import tra from './translation/tra.json';
import sim from './translation/sim.json';
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
} from 'react-switch-lang';
setTranslations({ eng, tra, sim  });
setDefaultLanguage('eng');
 
// If you want to remember selected language
setLanguageCookie();

function App() {
  const [login, setLogin] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  


  const handleLogin = async() => {
    console.log('login')
    console.log(username)
    const jsonBody = JSON.stringify({username:username,password:password})
    const response = await fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: jsonBody
    })
    const body = await response.json()
    if (response.status === 400)
    {
      alert(body.message)
    }
    if(body.message === 'success'){
      console.log('success login')
      setLogin(true);


    }
  }

  const handleUsername = (username) => {
    setUsername(username)

  }
  const handlePassword = (password) => {
    setPassword(password)
   
  }

  const handleLogout = e => {
    e.preventDefault();
    setLogin(false);
  }
    return (
        <main>
            <Switch>
                <Route path="/" component={Main} exact />
                <Route path="/about" component={About} exact />
                <Route path="/involved" component={Involved} exact />
                <Route path="/archive" component={Archive} exact />
                <Route path="/archiveitem" component={ArchiveItem} exact />
                <Route path="/privacy" component={Privacy} exact />
                <Route path="/sma" component={SMA} exact />
                <Route path="/contact" component={Contact} exact />
                <Route path="/donate" component={Donate} exact />
                <Route path="/cms/login" handleLogin={handleLogin} render = {
                    props => <Login {...props} login={login} username = {username} password = {password} handleLogin={handleLogin} handleUsername = {handleUsername} handlePassword = {handlePassword} />
                } />
                <ProtectedRoute exact path="/cms/" login = {login} component={Home} exact />
                <ProtectedRoute exact path="/cms/managenews" login = {login} component={ManageNews} exact />
                <ProtectedRoute exact path="/cms/editnews" login = {login} component={EditNews} exact />
                <ProtectedRoute exact path="/cms/addnews" login = {login} component={AddNews} exact />
                <ProtectedRoute exact path="/cms/donor" login = {login} component={Donor} exact />
                <ProtectedRoute exact path="/cms/special" login = {login} component={Special} exact />
                <ProtectedRoute exact path="/cms/patient" login = {login} component={Patient} exact />
                <ProtectedRoute exact path="/cms/manageappreciation" login = {login} component={ManageAppreciation} exact />
                <ProtectedRoute exact path="/cms/newappreciation" login = {login} component={NewAppreciation} exact />
                <ProtectedRoute exact path="/cms/editappreciation" login = {login} component={EditAppreciation} exact />
                <ProtectedRoute exact path="/cms/orgstructure" login = {login} component={OrgStructure} exact />
                <ProtectedRoute exact path="/cms/updatechart" login = {login} component={UpdateChart} exact />
                <ProtectedRoute exact path="/cms/volunteer" login = {login} component={Volunteer} exact />
                <ProtectedRoute exact path="/cms/managecharityitems" login = {login} component={ManageCharityItems} exact />
                <ProtectedRoute exact path="/cms/addcharityitem" login = {login} component={AddCharityItem} exact />
                <ProtectedRoute exact path="/cms/editcharityitem" login = {login} component={EditCharityItem} exact />
                <Route exact path='/unauthorized' component={Unauthorized} />
            </Switch>   
        </main>
    );
}

export default App;