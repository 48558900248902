
import React, { Component, useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom"
import './Donation.css';
import { PayPalButton } from "react-paypal-button-v2";
import { translate,getLanguage } from 'react-switch-lang';
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
const Donation = (props) => {
	const { t } = props;
	const [type, setType] = useState('paypal')
	const [money, setMoney] = useState('200')
	const [paid,setPaid] = useState(false)
	const [receipt,setReceipt] = useState(false)
	const [other, setOther] = useState(false)

	const sendEmail = (details) =>{
		let receipt_binary = receipt ? "Yes" : "No"
		console.log(receipt_binary)
	  	console.log('sending email')
	  	emailjs.send("service_53h0kza","template_9v07vzj",
	  		{
	  			email:details.payer.email_address,
	  			time:details.create_time,
	  			first_name:details.payer.name.given_name,
	  			last_name:details.payer.name.surname,
	  			amount:details.purchase_units[0].amount.value,
	  			receipt:receipt_binary

	  		}).then((response) => {
		       console.log('SUCCESS!', response.status, response.text);
		    }, (err) => {
		       console.log('FAILED...', err);
		    });
	  }
	const toggleClass = (info) => {
		setType(info) 

	}
	const toggleMoney = (money) => {
		setMoney(money)
	}

	const setOtherMoney = (event) => {
		setMoney(event.target.value)
	}

	const toggleOther = () => {
		setOther(true)
	}

	const handleCheckbox = (event) => {
		const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		setReceipt(value)
		console.log(receipt)

	}




		return (
			<div>
				<div class = 'd-container'>
					<div class = 'd-text'> {t('donation.title')} </div>
					<div class = 'd-four-columns'>
						<Button onClick = {()=> toggleClass('paypal')} variant= {type=='paypal'?'info':'outline-info'}>{t('donation.paypal')}</Button>
						<Button onClick = {()=> toggleClass('bank')} variant={type=='bank'?'info':'outline-info'}>{t('donation.banktransfer')}</Button>
						<Button onClick = {()=> toggleClass('cheque')} variant={type=='cheque'?'info':'outline-info'}>{t('donation.cheque')}</Button>
						<Button onClick = {()=> toggleClass('pps')} variant={type=='pps'?'info':'outline-info'}>{t('donation.pps')}</Button>
					</div>
					{(() => 
						{
							switch(type){
								case 'paypal':
								return(
									<div>
										{!paid
											?<div>
												<p class = 'd-small-text'>  {t('donation.monthly')}</p>
												<div class = 'd-info'>
												{t('donation.tel')}: 2811 1767   <br />
												{t('donation.email')}: info@fsma.org.hk 
												</div>

												<div class = 'd-text'> {t('donation.amountToGive')} </div>
												<div class = 'd-four-columns'>
													<Button onClick = {()=> toggleMoney('200')} variant={money=='200'?'info':'outline-info'}>$200</Button>
													<Button onClick = {()=> toggleMoney('500')} variant={money=='500'?'info':'outline-info'}>$500</Button>
													<Button onClick = {()=> toggleMoney('1000')} variant={money=='1000'?'info':'outline-info'}>$1000</Button>
													{console.log(money)}
													{other
														?<input class = 'other-btn' onChange= {setOtherMoney} placeholder = '$'/>
														:<Button onClick = {toggleOther} variant={money=='Others'?'info':'outline-info'}>{t('donation.others')}</Button>
										
													}
												</div>
												<div class = 'd-receipt-row'>
													<input type="checkbox" class = 'receipt-checkbox' onChange={handleCheckbox}/> <p class = 'd-receipt-text'> {t('donation.receipt1')}<br /> {t('donation.receipt2')} </p>
												</div>
												<div class = 'd-last-row'>
													 <PayPalButton
											              amount={money}
											              currency={'HKD'}
											              onSuccess={(details, data) =>  
											              	{
											              		console.log(details)
											              		setPaid(true)
											              		sendEmail(details)
											              	}
											              }

											              options={{
											                clientId: "AWX7gZZw3RLTj27smDQkBMs78mIhFbb2Naw7xJMyWc39ECoIBXUB-zH_MtlLCsjcVoy3pGizcQ3ykvPB"
											              }}
											          />
												</div>
											</div>
											:<div class = 'paypal-thankyou'>
												<img src = 'thanks.png' class = 'paypal-thanks-image' />
												<p>{t('donation.thanks1')}</p>
												<p>{t('donation.thanks2')} </p>
											</div>
										}
									</div>
								)
								case 'bank':
								return (
									<div>
										<div class = 'donation-number-row'>
											<div class = 'donation-number'>
											1
											</div>
											<div>
												<p> {t('donation.bt1')}  <a href = {t('donation.form')} target="_blank">
												<Button variant = 'outline-info'> <img src = 'pdf.png'  class= 'pdf-icon'/>{t('donation.donationForm')}  </Button>
												</a>
												</p>
												<p class = 'd-small-text'>  {t('donation.monthly')} </p>
												<div class = 'd-info'>
													{t('donation.tel')} : 2811 1767   <br />
													{t('donation.email')} : info@fsma.org.hk 
												</div>
											</div>
										</div>
										<div class = 'donation-number-row'>
											<div class = 'donation-number'>
											2
											</div>
											<div>
											<p>{t('donation.bt2')}<br/>{t('donation.bt3')}<b> 049-698921-838</b></p>
											</div>
										</div>
										<div class = 'donation-number-row'>
											<div class = 'donation-number'>
											3
											</div>
											<div>
											{getLanguage() == 'eng'
												?
												<div>
												<p>Please mail or fax <b>original pay-in-slip</b> together with the <b>donation form</b> to us.</p>
												<p>Fax: 2510 7199 </p>
												<p>Room 1402, 14/F, SUP Tower, 83 King's Road, North Point, Hong Kong</p>
												</div>
												:
												<div>
												<p>​請將入數紙郵寄或傳真給我們</p>
												<p>傳真: 2510 7199 </p>
												<p>地址：​香港北角英皇道83號聯合出版大廈14樓1402室</p>
												</div>
											}

											</div>
										</div>
									</div>
								)
								case 'cheque':
								return (
									<div>
										<div class = 'donation-number-row'>
											<div class = 'donation-number'>
											1
											</div>
											<div>
												<p> {t('donation.cheque1')} <a href = {t('donation.form')} target="_blank">
												<Button variant = 'outline-info'> <img src = 'pdf.png'  class= 'pdf-icon'/> {t('donation.donationForm')} </Button>
												</a>
												</p>
											</div>
										</div>
										<div class = 'donation-number-row'>
											<div class = 'donation-number'>
											2
											</div>
											<div>
											<p>{t('donation.cheque2')} <b>{t('donation.cheque3')}</b></p>
											<img src = 'cheque.png' />
											</div>
										</div>
										<div class = 'donation-number-row'>
											<div class = 'donation-number'>
											3
											</div>
											<div>
											<p>{t('donation.cheque4')}</p>
											<p>{t('donation.cheque5')}</p>
											</div>
										</div>
									</div>
									)
								case 'pps':
								return(
									<div>
									{getLanguage()=='eng'
										?
										<div>
											<div class = 'donation-icon-row'>
														<img src = 'phone.png' class = 'd-icon' />
														<div>
															<p> Call PPS at 18031 </p>
														</div>
												</div>
												<p class = 'donation-or'>OR</p>
												<div class = 'donation-icon-row'>
														<img src = 'website.png' class = 'd-icon'/>
														<div>
															<p> Visit website www.ppshk.com	</p>
															<p> PPS Merchant Code：9415	</p>
															<p> Please state your telephone number	</p>
														</div>
												</div>
										</div>
										:
										<div>
											<div class = 'donation-icon-row'>
														<img src = 'phone.png' class = 'd-icon' />
														<div>
															<p> 請致電 18031  </p>
														</div>
												</div>
												<p class = 'donation-or'>或</p>
												<div class = 'donation-icon-row'>
														<img src = 'website.png' class = 'd-icon'/>
														<div>
															<p> 登入網站 www.ppshk.com	</p>
															<p> 商戶編號：9415	</p>
															<p> 請用八位電話號碼作捐款編號	</p>
														</div>
												</div>
										</div>
									}
									</div>
									)
							}
						})()}
						<div> <Link to="/privacy" activeClassName="active-link">{t('pics')}</Link> </div>
				</div>
				     
			</div>
			)
		}

export default translate(Donation);
