import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom"
import { CloudinaryContext, Image } from "cloudinary-react";
import './Special.css'
import Confirmation from '../components/Confirmation.js'
import { openUploadWidget } from "../CloudinaryService";
const Special = () => {
  const [special, setSpecial] = useState([[[]],[[]],[[]],[[]],[[]],[[]]])
  const [show, setShow] = useState(false)
  const callApi = async () => {
    const response = await fetch('/api/special');
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);

    return body;
  };
  useEffect(() => {
    callApi()
      .then(res => 
        {
          const specialBody = res.special;
          setSpecial(specialBody)
        })
      .catch(err => console.log(err));
  }, []);

  const beginUpload = tag => {
  const uploadOptions = {
    cloudName: "dn5v5udu6",
    tags: [tag, 'anImage'],
    uploadPreset: "upload"
  };

  const handleSubmit = async public_id => {
    const response = await fetch('/api/addspecial', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({photo_id: public_id, num: tag }),
    });
    const body = await response.text() 
    setShow(true);
    callApi().then(res => 
        {
          const specialBody = res.special;
          console.log(res.special);
          setSpecial(specialBody)
        })
      .catch(err => console.log(err));
     setInterval(() => {
      setShow(false);
    }, 1500);

  };
  openUploadWidget(uploadOptions, (error, photos) => {
    if (!error) {
      console.log(photos);
      if(photos.event === 'success'){
        handleSubmit(photos.info.public_id)

      }
    } else {
      console.log(error);
    }
  })
}

  return (
    <CloudinaryContext cloudName="dn5v5udu6">
      <Confirmation show = {show} sentence = 'Image Uploaded'/>
      <div class = 'special-container'>
            <Link to="/cms" class = 'home-link'> Back Home </Link>
              <h2> Edit Special Appreciation </h2>
              <div class = 'special-subcontainer'>
                <div className="special-box">
                  <Image publicId={special[0].photo_id}></Image>
                    <button onClick={() => beginUpload(1)}>Upload Image</button>
                </div>
                <div className="special-box">
                  <Image publicId={special[1].photo_id}></Image>
                    <button onClick={() => beginUpload(2)}>Upload Image</button>
                </div>
                <div className="special-box">
                  <Image publicId={special[2].photo_id}></Image>
                    <button onClick={() => beginUpload(3)}>Upload Image</button>
                </div>
                <div className="special-box">
                  <Image publicId={special[3].photo_id}></Image>
                    <button onClick={() => beginUpload(4)}>Upload Image</button>
                </div>
                <div className="special-box">
                  <Image publicId={special[4].photo_id}></Image>
                    <button onClick={() => beginUpload(5)}>Upload Image</button>
                </div>
                <div className="special-box">
                  <Image publicId={special[5].photo_id}></Image>
                    <button onClick={() => beginUpload(6)}>Upload Image</button>
                </div>
              </div>
      </div>
    </CloudinaryContext>
  );
}
export default Special