import React, {useState, useEffect, useMemo} from 'react';
import { CloudinaryContext, Image } from "cloudinary-react";
import { Link } from "react-router-dom"
import './ManageAppreciation.css'
import { openUploadWidget } from "../CloudinaryService";
import Confirmation from '../components/Confirmation.js'
import Button from 'react-bootstrap/Button';
import { confirmAlert } from 'react-confirm-alert'; // Import
const ManageAppreciation = () => {
  const [show, setShow] = useState(false)
  const [newForm, setNewForm] = useState(false)
  const [notes, setNotes] = useState([])


    const callApi = async () => {
	    const response = await fetch('/api/notes', {
        method: "GET"
      })
	    const body = await response.json();
	    if (response.status !== 200) throw Error(body.message);

	    return body;
	  };

    useEffect(() => {
	    callApi()
	      .then(res => 
	        {
	          const notesBody = res.notes;
	          console.log(res)
	          setNotes(notesBody)
	        })
	      .catch(err => console.log(err));
	}, []);


	const deletePopup = (id, title) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want to delete the post: ' + title + 's note',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteClicked(id)
        },
        {
          label: 'No',
          onClick: () => alert('You have not deleted the post: ' + title  + 's note' )
        }
      ]
    });
  };
	const deleteClicked= async (id) => {
       const response = await fetch('/api/deleteNote', {
	      method: 'delete',
	      headers: {
	        'Content-Type': 'application/json',
	      },
	      body: JSON.stringify({note_id:id}),
	    });
       const body = await response.text().then(response => {window.location.reload(true)})
    };
  
	const notesList = notes.map((note) =>
		<div class = 'ma-box'>
		<Link to={{
			    pathname: "/cms/EditAppreciation",
			    state: { _id: note._id}
			  }}
			>Edit {note.note.eng.author}'s note
		</Link> 
		<Link to = {{ pathname: "/cms/EditAppreciation",
			    state: { _id: note._id}
			}}
	    >
	    	<b class = 'ma-edit-link'>Edit</b>
	    </Link>
	        	<a class = 'ma-x' onClick={()=>deletePopup(note._id,note.note.eng.author)}> X </a>
		</div>
	);

  return (
    <CloudinaryContext cloudName="dn5v5udu6">
    	<Confirmation show = {show} sentence = 'PDF Uploaded'/>
    	<div class = 'ma-container'>
        	<Link to="/cms" class = 'home-link'> Back Home </Link>
	        	<h2> Manage Notes of Appreciation </h2>
	        <div class = 'ma-subcontainer'>
	        	{notesList}
	        </div>
	        <Link to="/cms/newappreciation" > <button>+ Add New Note</button> </Link>
	    </div>
    </CloudinaryContext>
  );
}
export default ManageAppreciation