import React, { useState, useEffect } from 'react'
import Contact2 from './components/Contact.js'
import Header from './Header.js'
import Footer from './Footer.js'
import { translate,getLanguage } from 'react-switch-lang';
const Contact = (props) => {
	const { t } = props;
	return (
	    <div>
		    <Header></Header>
			<Contact2/>
			<Footer />
		</div>
	);
}

export default translate(Contact);
