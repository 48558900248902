import React, { useState, useEffect } from 'react'
import Contact from './components/Contact.js'
import Header from './Header.js'
import Footer from './Footer.js'
import { translate,getLanguage } from 'react-switch-lang';
const Privacy = (props) => {
	const { t } = props;


	return (
        <div>
        <Header></Header>
        {getLanguage() =='eng'
        ?
        <div style = {{margin:'16% 5%'}}>
        <div style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif', border: 'solid windowtext 1.0pt', padding: '1.0pt 4.0pt 1.0pt 4.0pt'}}>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif', border: 'none', padding: '0in'}}><strong>PRIVACY POLICY AND PERSONAL INFORMATION COLLECTION STATEMENTS</strong></p>
        </div>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif'}}><strong>&nbsp;</strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif'}}>In accordance with the Personal Data (Privacy) Ordinance, Families of SMA Foundation Ltd. (“FSMA”) formerly known as Families of SMA Charitable Trust, has adopted the following policies to safeguard data subject’s personal data and is committed to protecting its confidentiality. Please read our Privacy Policy and Personal Information Collection Statements herein before submitting your personal data.</p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif'}}><strong>Purpose of Collection</strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif'}}>FSMA collects and handles personal data and information for the following purposes:</p>
        <ol style={{listStyleType: 'undefined', marginLeft: '0.25in'}}>
        <li>Proper assessment and due diligence work associated with applications from potential beneficiaries of our charitable services or financial assistance in furtherance of the mission of the charity section 88 of the Inland Revenue Ordinance;</li>
        <li>Identification of donors of cash or benefits-in-kind for conveyance of gratitude and/or facilitate FSMA’s acknowledgement of receipt of donations and/or furnishing official receipts;</li>
        <li>Identification and contact of volunteers who may want to participate in FSMA’s work and/or activities.</li>
        </ol>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif'}}>Pursuant to the above purposes, please note that the supply of personal data is purely voluntary and data subject may decline to provide any requested information/data. However, insufficient data or information may impede or stifle the handling of any request for approval of applications for assistance, acceptance of unknown donations, sending out official receipts to legitimate donors or registration of volunteers.</p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif'}}><strong>Access to Personal Data</strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif'}}>Only designated employees and/or registered volunteers who need to carry out activities, duties or functions of FSMA are permitted to view your personal data. FSMA may disclose your personal data to any relevant personnel, agent, contractor or service provider who carry out the legitimate services for FSMA in connection with our operations or where you have given your consent. Where we share data with third parties, we take reasonable steps to ensure that the parties are aware of and comply with applicable data protection legislation to maintain personal data appropriately.</p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif'}}>Apart from the purpose of mailing updates or pursuant to FSMA’s donation drives and awareness campaigns, FSMA will not share, rent, sell or transmit personal data to other organisations or for use in unrelated purpose or in commercial direct marketing.</p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif'}}>Any data or information collected is for the afore-stated purposes of the charitable objectives of FSMA and will not be transmitted or shared with any third party without the express and voluntary consent of the data subject. FSMA will not disclose any individual information to other organisations or persons unless release is required by law or is pertinent to judicial or government investigations or proceedings.</p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif'}}><strong>Access to and Correction of Personal Data</strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif'}}>The data subject may, at any time, request access to your personal data for update, correction or deletion by contacting any member of FSMA staff at 28111767 or by mail at Room 1402, SUP Tower, 83 Kings Road, North Point, Hong Kong or email <a href="mailto:info@fsma.org.hk">info@fsma.org.hk</a></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif'}}><strong>Change of Privacy Policy</strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif'}}>FSMA reserves the right to change this privacy policy from time to time, in accordance with the laws of Hong Kong, by updating this page.</p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif'}}>&nbsp;</p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif'}}>&nbsp;</p>
        </div>
        :getLanguage()=='tra'
        ?
        <div style = {{margin:'16% 5%'}}>
        <div style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif', border: 'solid windowtext 1.0pt', padding: '1.0pt 4.0pt 1.0pt 4.0pt'}}>

        <p>私隱政策和個人資料/數據收集聲明</p>
        </div>
        <p>&nbsp;</p>
        <p>根據《個人資料（私隱）條例》，脊髓肌肉萎縮症慈善基金有限公司的(前身為 脊髓肌肉萎縮症慈善基金)，已採用以下政策來保護資料當事人的個人資料/數據，並致力於保護其機密性。在提交您的個人資料/數據之前，請閱讀我們的私隱政策和個人資料/數據收集聲明。</p>
        <p>&nbsp;</p>
        <p>收集目的</p>
        <p>脊髓肌肉萎縮症慈善基金有限公司出於以下目的收集和處理個人資料/數據：</p>
        <p>&nbsp;</p>
        <ol>
        <li>與我們慈善服務或財政援助的潛在受益人的申請相關的適當評估和盡職調查工作，以促進《稅務條例》第 88 條慈善事業的使命;</li>
        <li>識別現金或實物捐助者以表達感謝和/或促進脊髓肌肉萎縮症慈善基金有限公司確認收到捐贈和/或提供正式收據；</li>
        <li>可能想參與脊髓肌肉萎縮症慈善基金有限公司工作和/或活動的義工的識別和聯繫。</li>
        </ol>
        <p>&nbsp;</p>
        <p>根據上述目的，請注意個人資料/數據的提供純屬自願，資料/數據當事人可能會拒絕提供任何要求的資料/數據。但是，資料/數據不足可能會阻礙或扼殺任何請求批准援助的申請、接受未知捐贈、向合法捐贈者發送正式收據或志願者註冊的請求。</p>
        <p>&nbsp;</p>
        <p>訪問個人資料/數據</p>
        <p>僅允許需要執行脊髓肌肉萎縮症慈善基金有限公司活動、職責或職能的指定員工和/或註冊義工查看您的個人資料/數據。脊髓肌肉萎縮症慈善基金有限公司可能會向任何為脊髓肌肉萎縮症慈善基金有限公司提供與我們營運相關的合法服務的相關人員、代理、承包商或服務提供商，披露您的個人資料/數據，或在您同意的情況下。當我們與第三方共享資料/數據時，我們會採取合理措施確保各方了解並遵守適用資料/數據受保護的法例，以適當地維護個人資料/數據。</p>
        <p>&nbsp;</p>
        <p>除了郵寄更新的目的或根據脊髓肌肉萎縮症慈善基金有限公司的捐贈活動和宣傳認知活動外，脊髓肌肉萎縮症慈善基金有限公司不會向其他組織共享、出租、出售或傳輸個人資料/數據，或用於無相關目的或商業營銷。</p>
        <p>&nbsp;</p>
        <p>收集的任何資料/數據均用於脊髓肌肉萎縮症慈善基金有限公司上述提及的目的慈善用途，未經資料/數據當事人的明確和自願同意下，亦不會被傳輸或與任何第三方共享。脊髓肌肉萎縮症慈善基金有限公司不會向其他組織或個人披露任何個人資料/數據，除非法律要求披露或與司法或政府調查或訴訟有關。</p>
        <p>&nbsp;</p>
        <p>訪問和更正個人資料/數據</p>
        <p>資料/數據當事人可隨時致電 2811 1767 聯絡任何一位脊髓肌肉萎縮症慈善基金有限公司工作人員或郵寄至香港北角英皇道 83 號聯合出版大廈 1402 室，或 info@fsma.org.hk，要求查閱您的個人資料以進行更新、更正或刪除電郵。</p>
        <p>&nbsp;</p>
        <p>私隱政策的變更</p>
        <p>脊髓肌肉萎縮症慈善基金有限公司保留根據香港法律通過更新此頁面不時更改私隱政策的權利。</p>
        </div>
        :              
         <div style = {{margin:'16% 5%'}}>
        <div style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '16px', fontFamily: '"Tahoma",sans-serif', border: 'solid windowtext 1.0pt', padding: '1.0pt 4.0pt 1.0pt 4.0pt'}}>
        <p>私隐政策和个人资料/数据收集声明</p>
        </div>
        <p>根据《个人资料（私隐）条例》，脊髓肌肉萎缩症慈善基金有限公司的(前身为 脊髓肌肉萎缩症慈善基金)，已采用以下政策来保护资料当事人的个人资料/数据，并致力于保护其机密性。在提交您的个人资料/数据之前，请阅读我们的私隐政策和个人资料/数据收集声明。</p>
        <p>&nbsp;</p>
        <p>收集目的</p>
        <p>脊髓肌肉萎缩症慈善基金有限公司出于以下目的收集和处理个人资料/数据：</p>
        <p>&nbsp;</p>
        <ol>
        <li>与我们慈善服务或财政援助的潜在受益人的申请相关的适当评估和尽职调查工作，以促进《税务条例》第 88 条慈善事业的使命;</li>
        <li>识别现金或实物捐助者以表达感谢和/或促进脊髓肌肉萎缩症慈善基金有限公司确认收到捐赠和/或提供正式收据；</li>
        <li>可能想参与脊髓肌肉萎缩症慈善基金有限公司工作和/或活动的义工的识别和联系。</li>
        </ol>
        <p>&nbsp;</p>
        <p>根据上述目的，请注意个人资料/数据的提供纯属自愿，资料/数据当事人可能会拒绝提供任何要求的资料/数据。但是，资料/数据不足可能会阻碍或扼杀任何请求批准援助的申请、接受未知捐赠、向合法捐赠者发送正式收据或志愿者注册的请求。</p>
        <p>&nbsp;</p>
        <p>访问个人资料/数据</p>
        <p>仅允许需要执行脊髓肌肉萎缩症慈善基金有限公司活动、职责或职能的指定员工和/或注册义工查看您的个人资料/数据。脊髓肌肉萎缩症慈善基金有限公司可能会向任何为脊髓肌肉萎缩症慈善基金有限公司提供与我们营运相关的合法服务的相关人员、代理、承包商或服务提供商，披露您的个人资料/数据，或在您同意的情况下。当我们与第三方共享资料/数据时，我们会采取合理措施确保各方了解并遵守适用资料/数据受保护的法例，以适当地维护个人资料/数据。</p>
        <p>&nbsp;</p>
        <p>除了邮寄更新的目的或根据脊髓肌肉萎缩症慈善基金有限公司的捐赠活动和宣传认知活动外，脊髓肌肉萎缩症慈善基金有限公司不会向其他组织共享、出租、出售或传输个人资料/数据，或用于无相关目的或商业营销。</p>
        <p>&nbsp;</p>
        <p>收集的任何资料/数据均用于脊髓肌肉萎缩症慈善基金有限公司上述提及的目的慈善用途，未经资料/数据当事人的明确和自愿同意下，亦不会被传输或与任何第三方共享。脊髓肌肉萎缩症慈善基金有限公司不会向其他组织或个人披露任何个人资料/数据，除非法律要求披露或与司法或政府调查或诉讼有关。</p>
        <p>&nbsp;</p>
        <p>访问和更正个人资料/数据</p>
        <p>资料/数据当事人可随时致电 2811 1767 联络任何一位脊髓肌肉萎缩症慈善基金有限公司工作人员或邮寄至香港北角英皇道 83 号联合出版大厦 1402 室，或 info@fsma.org.hk，要求查阅您的个人资料以进行更新、更正或删除电邮。</p>
        <p>&nbsp;</p>
        <p>私隐政策的变更</p>
        <p>脊髓肌肉萎缩症慈善基金有限公司保留根据香港法律通过更新此页面不时更改私隐政策的权利。</p>
        <p>&nbsp;</p>
        </div>
    }

    <Contact/>
    <Footer />
    </div>
    )}
export default translate(Privacy);