import React, { useState, useEffect } from 'react'
import './About.css'
import Donation from './components/Donation.js'
import { Link } from "react-router-dom"
import { CloudinaryContext, Image } from "cloudinary-react";
import Header from './Header.js'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import Footer from './Footer.js'
import Contact from './components/Contact.js'
import { translate,getLanguage } from 'react-switch-lang';
const About = (props) => {
	  const [notes, setNotes] = useState([{
	  	note:{
		  	coverId:'',
		  	eng:{
		  		author:'',
		  		details:'',
		  		content:''
		  	},
		  	tra:{
		  		author:'',
		  		details:'',
		  		content:''
		  	},
			sim:{
		  		author:'',
		  		details:'',
		  		content:''
		  	}
		  }
	  }])
	const [forms,setForms] = useState([]);
	const [chart,setChart] = useState({
		eng:{
			coverId:''
		},
		tra:{
			coverId:''
		},
		sim:{
			coverId:''
		}
	});
	const [patron, setPatron] = useState([ 
		    {
		      order:0, 	
		      patronName: '',
		      patronTitle: ''
		    },
		]
	);

	const [staff, setStaff] = useState([
		    {
		      order:0, 	
		      staffName: '',
		      staffTitle: ''
		    },
	]);
	const [names, setNames] = useState({
		founder:'',
		trustee:'',
		members:'',
		advisors:'',
		membersSplit:[],
		advisorsSplit:[]
	})

	
	 useEffect(() => {
		const callNotes = async () => {
	   	    const response = await fetch('/api/notes');
		    const body = await response.json();
		    if (response.status !== 200) throw Error(body.message);

		    return body;
	    }
	    const callForms = async () => {
	   	    const response = await fetch('/api/forms');
		    const body = await response.json();
		    if (response.status !== 200) throw Error(body.message);

		    return body;
	    }
	    const callOrg = async () => {

	      const response = await fetch('/api/orgStructure')
	      const body = await response.json();
	      if (response.status !== 200) throw Error(body.message);

	      return body;
	    };

	    const callChart = async () => {

	      const response = await fetch('/api/getChart')
	      const body = await response.json();
	      if (response.status !== 200) throw Error(body.message);

	      return body;
	    };
	    callChart()
	      .then(res => 
	        {
	          const chartBody = res.chart;
	          setChart(chartBody[0])
	        })
	      .catch(err => console.log(err));
	    callOrg()
	      .then(res => 
	        {
	          const orgBody = res.orgStructure[0];
	          const lang = getLanguage()
	          setNames({ 
		  		...names,
		  		founder:orgBody.combined[lang].founder,
				trustee:orgBody.combined[lang].trustee,
				members:orgBody.combined[lang].members,
				advisors:orgBody.combined[lang].advisors,
				membersSplit:orgBody.combined[lang].membersSplit,
				advisorsSplit:orgBody.combined[lang].advisorsSplit
		  	  })
		  	  if(lang == 'eng')
		  	  {
		  	  setStaff(orgBody.combined.eng.engStaff)
		  	  setPatron(orgBody.combined.eng.engPatron)
		  	  }
		  	  if(lang=='tra')
		  	  {
		  	  setStaff(orgBody.combined.tra.traStaff)
		  	  setPatron(orgBody.combined.tra.traPatron)
		  	  }
		  	  if(lang=='sim')
		  	  {
		  	  setStaff(orgBody.combined.sim.simStaff)
		  	  setPatron(orgBody.combined.sim.simPatron)
		  	  }

	        })
	      .catch(err => console.log(err));
	     const interval=setInterval(()=>{
     		    callOrg()
	      .then(res => 
	        {
	          const orgBody = res.orgStructure[0];
	          const lang = getLanguage()
	          setNames({ 
		  		...names,
		  		founder:orgBody.combined[lang].founder,
				trustee:orgBody.combined[lang].trustee,
				members:orgBody.combined[lang].members,
				advisors:orgBody.combined[lang].advisors,
				membersSplit:orgBody.combined[lang].membersSplit,
				advisorsSplit:orgBody.combined[lang].advisorsSplit
		  	  })
		  	  if(lang == 'eng')
		  	  {
		  	  setStaff(orgBody.combined.eng.engStaff)
		  	  setPatron(orgBody.combined.eng.engPatron)
		  	  }
		  	  if(lang=='tra')
		  	  {
		  	  setStaff(orgBody.combined.tra.traStaff)
		  	  setPatron(orgBody.combined.tra.traPatron)
		  	  }
		  	  if(lang=='sim')
		  	  {
		  	  setStaff(orgBody.combined.sim.simStaff)
		  	  setPatron(orgBody.combined.sim.simPatron)
		  	  }

	        })
     	},4000)
       callNotes()
	      .then(res => 
	        {
	          console.log(res.notes) 	
	          setNotes(res.notes)
	        })
	      .catch(err => console.log(err));
	    callForms()
	      .then(res => 
	        {
	          setForms(res.forms)
	        })
	      .catch(err => console.log(err));	    
       
     return()=>clearInterval(interval)
	  }, []); 

	const notesList = notes.map((note, idx) =>	{
			const lang = getLanguage()
			return(
			<Carousel.Item>
		  		<Card className='appreciation'>
		  		{parseInt(idx%2)<1?<Card.Body className = 'card-color'>
		  		<div class = 'appreciation-card'>
				  		<div class = 'appreciation-title'>
				  			<Image publicId={note.note.coverId}></Image>
				  			<p><b>{note.note[lang].author}</b> </p>
							<p>{note.note[lang].details}</p>
				  		</div>
				  		<div class = 'appreciation-words'>
				  			<p>{note.note[lang].content}</p>
				  		</div>
				  	</div>
				  </Card.Body>
				:<Card.Body className = 'card-color-blue'>
				  	<div class = 'appreciation-card'>
				  		<div class = 'appreciation-title'>
				  			<Image publicId={note.note.coverId}></Image>
				  			<p><b>{note.note[lang].author}</b> </p>
							<p>{note.note[lang].details}</p>
				  		</div>
				  		<div class = 'appreciation-words'>
				  			<p>{note.note[lang].content}</p>
				  		</div>
				  	</div>
				  </Card.Body>
				}
				</Card>
		  </Carousel.Item> 
		)
	})

	const formsList = forms.slice(0,2).map((form, idx) =>
		<div class = {form.lang.includes(getLanguage()) ?'form-container':'absolute'}>
			{form.lang.includes(getLanguage()) 
			?
				<div>
					<img src = './pdf_white.png'></img>
					<div class = 'form-link'>
							<a href = {'https://res.cloudinary.com/dn5v5udu6/image/upload/fl_attachment/'+form.pdfId+'.pdf'}><b>{form[getLanguage()].formValue}</b><img src = './black_arrow.png' class = 'arrow'></img></a>		
					</div> 
				</div>
			:<div></div>
			}
		</div>
	)

	const formsList2 = forms.slice(2,forms.length).map((form, idx) =>
		<div class = {form.lang.includes(getLanguage()) ?'form-container':'absolute'}>
			{form.lang.includes(getLanguage()) 
			?
				<div>
					<img src = './pdf_white.png'></img>
					<div class = 'form-link'>
							<a href = {'https://res.cloudinary.com/dn5v5udu6/image/upload/fl_attachment/'+form.pdfId+'.pdf'}><b>{form[getLanguage()].formValue}</b><img src = './black_arrow.png' class = 'arrow'></img></a>		
					</div> 
				</div>
			:<div></div>
			}
		</div>
	)

	const patronList = patron.map((person) =>
		<div>
		<p class = 'about-sixth-first-name'>{person.patronName}</p>
		<p class = 'about-sixth-first-title'>{person.patronTitle}</p>
		</div>
	)

	const membersList = names.membersSplit.map((person)=>
	<div>
		<p class = 'about-sixth-first-name'>{person}</p>
	</div>
	)

	const advisorsList = names.advisorsSplit.map((person)=>
	<div>
		<p class = 'about-sixth-first-name'>{person}</p>
	</div>
	)

	const staffList = staff.map((person) =>
		<div class = 'about-sixth-item'>
			<p class = 'about-sixth-first-name'>{person.staffName}</p>
			<p class = 'about-sixth-first-title'>{person.staffTitle}</p>
		</div>
	)
	const { t } = props;
 
	return (
	    <CloudinaryContext cloudName="dn5v5udu6">
	    <Header></Header>
		<div class = 'about-color'>
			<div class = 'relative'>
				<img src= './about-ill.png' class = 'intro-about-ill' />
			</div>

			<div class = 'relative'>
				<img src= './about-dots.png' class = 'intro-about-dots' />
			</div>
			<div class = 'about'>
				<div class = 'about-sub'>
					<div class = 'about-words'>
							<h1>{t('about.title')} </h1>
							<p>{t('about.titleText')}</p> 

						</div>

						<div class = 'about-pic'>			
								<img src= './about-main.png' class = 'about-main'></img>
						</div>
					</div>
			</div>
			<div class = 'about-intro-second'>
				<div class = 'about-second-wave'>
				<img src= './wave.png' class='intro-second-image'></img>
			</div>
		</div>
		</div>

		<div class = 'about-second'>
			<div class = 'about-second-sub'>
			<div>

				<img src = './about-second.png' class = 'about-clip-circle'></img>


			</div>
			<div class = 'about-second-fluff'></div>
			<div class = 'about-second-words'>
								<div class = 'relative'>
						<img src= './about-wave.png' class = 'intro-about-wave' />
					</div>
				{getLanguage() =='eng'
						?<div>
							<h1> Our Charitable Objectives </h1>
							<ul>
								<li>Assist SMA patients and their family members to cope with challenges and lessen hardship and suffering</li>
								<li>Promote public awareness and education of SMA in Hong Kong and Mainland China</li>
								<li>Facilitate cooperation with health sectors and research centres</li>
								<li>Provide medical, logistics and emotional support to SMA patients and families</li>
							</ul>
						</div>
						:getLanguage() == 'tra'
							?<div>
								<h1> ​本會服務宗旨 </h1>
									<ul>
									<li>助患者和家人度過難關及舒緩該病所帶來的困難和痛苦</li>
										<li>喚起在香港和中國大陸公眾人士對SMA的認識、關注和了解</li>
										<li>促進與疾病研究中心的合作</li>
										<li>為SMA患者及家人提供醫療輔助、情緒支援及輔導</li>
									</ul>
							</div>
							:<div>
								<h1> ​本会服务宗旨 </h1>
								<ul>
								<li>助患者和家人度过难关及舒缓该病所带来的困难和痛苦</li>
								<li>唤起在香港和中国大陆公众人士对SMA的认识、关注和了解</li>
								<li>促进与疾病研究中心的合作</li>
								<li>为SMA患者及家人提供医疗辅助、情绪支援及辅导</li>
								</ul>
							</div>
						
				}

			</div>
			</div>
		</div>
		<div class = 'about-third'>
			<div class = 'about-third-sub'>
				<div class = 'about-third-title'>
					<div>
						<h1 class = 'about-third-h1'>{t('about.thirdTitle')}</h1>
						<p>{t('about.thirdText')}</p>
					</div>
				</div>
				<div class = 'about-third-row'>
					<div class = 'about-third-item'>
						<div>
							<img src= './services1.png'></img>

						</div>
						<div class = 'about-third-item-text'>
							<p><b>{t('about.third1t')}</b></p>
							<p>{t('about.third1b')}</p>
						</div>
					</div>
					<div class = 'about-third-item'>
						<div>
							<img src= './services2.png'></img>

						</div>
						<div class = 'about-third-item-text'>
							<p><b>{t('about.third2t')}</b></p>
							<p>{t('about.third2b')}</p>						
						</div>
					</div>
									<div class = 'about-third-item'>
						<div>
							<img src= './services3.png'></img>

						</div>
						<div class = 'about-third-item-text'>
							<p><b>{t('about.third3t')}</b></p>
							<p>{t('about.third3b')}</p>
							</div>
					</div>
									<div class = 'about-third-item'>
						<div>
							<img src= './services4.png'></img>

						</div>
						<div class = 'about-third-item-text'>
							<p><b>{t('about.third4t')}</b></p>
							<p>{t('about.third4b')}</p>
						</div>
					</div>
									<div class = 'about-third-item'>
						<div>
							<img src= './services5.png'></img>

						</div>
						<div class = 'about-third-item-text'>
							<p><b>{t('about.third5t')}</b></p>
							<p>{t('about.third5b')}</p>
						</div>
					</div>
									<div class = 'about-third-item'>
						<div>
							<img src= './services6.png'></img>

						</div>
						<div class = 'about-third-item-text'>
							<p><b>{t('about.third6t')}</b></p>
							<p>{t('about.third6b')}</p>
						</div>
					</div>
									<div class = 'about-third-item'>
						<div>
							<img src= './services7.png'></img>

						</div>
						<div class = 'about-third-item-text'>
							<p><b>{t('about.third7t')}</b></p>
							<p>{t('about.third7b')}</p>
						</div>
					</div>
									<div class = 'about-third-item'>
						<div>
							<img src= './services8.jpeg'></img>

						</div>
						<div class = 'about-third-item-text'>
							<p><b>{t('about.third8t')}</b></p>
							<p>{t('about.third8b')}</p>
						</div>
					</div>
					<div class = 'about-third-item'>
						<div>
							<img src= './services9.png'></img>

						</div>
						<div class = 'about-third-item-text'>
							<p><b>{t('about.third9t')}</b></p>
							<p>{t('about.third9b')}</p>
						</div>
					</div>
					<div class = 'about-third-item'>
						<div>
							<img src= './services10.jpeg'></img>

						</div>
						<div class = 'about-third-item-text'>
							<p><b>{t('about.third10t')}</b></p>
							<p>{t('about.third10b')}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class = 'about-fourth'>
			<div class = 'about-fourth-sub'>
				<div class = 'about-fourth-title'>
						<div>
							<h1 class = 'about-fourth-h1'>{t('about.fourthTitle')}</h1>
							<p>{t('about.fourthText')} <Link to="/privacy" activeClassName="active-link">{t('pics')}</Link> </p>
						</div>
				</div>
				<div class = 'about-fourth-forms'>
					{formsList}
					<div></div>
					{formsList2}
				</div>
			</div>
		</div>
		<div class = 'about-fifth'>
			<div class = 'about-fifth-sub'>
				<div class = 'about-fifth-title'>
					<h1 class = 'about-fifth-h1'>{t('about.fifthTitle')}</h1>
				</div>
				<div class = 'about-fifth-carousel'>
				<Carousel>
					{notesList}
				</Carousel>
				</div>
			</div>
		</div>
		<div class = 'about-sixth'>
			<div class = 'about-sixth-sub'>
				<div class = 'about-sixth-title'>
					<h1 class = 'about-sixth-h1'>{t('about.sixthTitle')}</h1>
				</div>
				<div class = 'about-sixth-first-row'>
					<div class = 'about-sixth-item'>
						<h2> {t('about.sixthPatron')} </h2>
						{patronList}
					</div>

					<div class = 'about-sixth-item'>
						<h2> {t('about.sixthFounder')} </h2>
						<p class = 'about-sixth-first-name'>{names.founder}</p>
					</div>
				</div>
				<div class = 'about-sixth-first-row'>
					<div class = 'about-sixth-item about-members'>
						<h2> {t('about.sixthMembers')} </h2>
						{membersList}
					</div>
					<div class = 'about-sixth-item'>
						<h2>{t('about.sixthMedical')}</h2>
						{advisorsList}
					</div>
				</div>
				<div class = 'about-fsma-staff'>
					<h2> {t('about.sixthStaff')} </h2>
				</div>
				<div class = 'about-sixth-last-row'>
					{staffList}
				</div>
			</div>
		</div>
		<div class = 'about-seventh'>
			<div class = 'about-seventh-sub'>
				<div class = 'about-seventh-title'>
					<h1 class = 'about-seventh-h1'>{t('about.seventhTitle')}</h1>
				</div>
				<div class = 'about-seventh-image'>
					<a href = 'https://res.cloudinary.com/dn5v5udu6/image/upload/v1637224901/2020_annual_report_k0ad4z.pdf'><img src = './annual2020.png'></img></a>
				</div>
			</div>
		</div>
				<Contact color='yellow'/>
				<Footer />
	</CloudinaryContext>
	);
}

export default translate(About);
