import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import './Contact.css'
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
import GoogleMapReact from 'google-map-react';
import { translate,getLanguage } from 'react-switch-lang';
init("user_sRmLw1359QUtu8osFqeaz");

const Contact = (props) => {
  const { t } = props;
  const AnyReactComponent = ({ text }) => <div>{text}</div>;
		const [sentEmail,setSentEmail] = useState(false)
		const [errorMessages, setErrorMessages] = useState([])
		const [person, setPerson] = useState({
			firstName:'',
			lastName:'',
			organization:'',
			email:'',
			message:''
		})

		const sendEmail = () =>{

		  	if((person.firstName == '') || (person.lastName = '') || (person.email == '') || (person.message == '')) {
		  		var tempMessages = new Array();
		  		if (person.firstName == '') {tempMessages.push("Please enter your first name") }
		  		if (person.lastName == '') {tempMessages.push("Please enter your last name") }
		  		if (person.email == '') {tempMessages.push("Please enter a correct email") }
		  		if (person.message == '') {tempMessages.push("Please enter a valid message") }
		  		setErrorMessages(tempMessages)
		  		console.log('missing fields')
		  	}
		  	else{
		  		emailjs.send("service_53h0kza","template_dg43zri",
		  		{
		  			firstName:person.firstName,
		  			lastName:person.lastName,
		  			email:person.email,
		  			message:person.message,
		  			organization:person.organization


		  		}).then((response) => {
		  		   console.log('sending email')
			       console.log('SUCCESS!', response.status, response.text);
			       setSentEmail(true)
			    }, (err) => {
			       console.log('FAILED...', err);
			    });

		  	}
		  }
		   const handleChange = (event) =>{
		  	setPerson({ 
		  		...person,
		  		[event.target.name]:event.target.value
		  		})
		  	console.log(person)
		  }

		  const showErrorMessages = errorMessages.map(message => <div class = 'message-redbox'>{message}</div>)
		return (
			<div>
				<div className={(props.color=='yellow' ? 'contact-container-yellow' : 'contact-container')}>
					<div class = 'contact-sub'>
						<h1>{t('contact.title')}</h1>
						<div class = 'contact-row'>
						{sentEmail
							?<div class = 'contact-thankyou'>
								<img src='./thanks.png' class = 'thanks'/>
								<p><b>{t('contact.thanks')}</b></p>
							</div>
							:<div>
								<div> {t('contact.hear')}</div>
								<div> {t('contact.inTouch')} </div>
								<div> <Link to="/privacy" activeClassName="active-link">{t('pics')}</Link> </div>
									<div class = 'contact-subcontainer'>
			      						<div class = 'contact-first-row'>
			      							<div>
			      								<div>{t('contact.firstName')}</div>
			      								<input placeHolder = {t('contact.firstName')} name = 'firstName' value={person.firstName} onChange={handleChange} />
			      							</div>
			      							<div>
			      								<div>{t('contact.lastName')}</div>
			      								<input placeHolder = {t('contact.lastName')} name = 'lastName' value={person.lastName} onChange={handleChange} />
			      							</div>
			      						</div>
			  							<div class = 'contact-first-row'>
			  								<div>
				  								<div>{t('contact.email')}</div>
				  								<input placeHolder = 'email123@gmail.com' name = 'email' value={person.email} onChange={handleChange} />
			  								</div>
			  								<div>
				  								<div>{t('contact.organization')}</div>
				  								<input placeHolder = {t('contact.companySample')} name = 'organization' value={person.organization} onChange={handleChange} />
			  								</div>
			  							</div>
			  							 <div>
				  								<div>{t('contact.message')}</div>
				  								<textarea class ='contact-message' name = 'message' value={person.message} onChange={handleChange} />
			  							</div>
			  							{showErrorMessages}
			      							<div class = 'contact-button-container'>
			      								<Button variant="contact2" onClick={sendEmail}>{t('contact.submit')}</Button>
			      							</div>
			          					</div>
		          				</div>
		          		}

							<div class = 'contact-details'>
							<div class = 'contact-details-row'>{t('contact.tel')}:  2811 1767 </div>
							<div class = 'contact-details-row'>{t('contact.fax')}:  2510 7199 </div>
							<div class = 'contact-details-row'>{t('contact.email')}: info@fsma.org.hk </div>
							<div class = 'contact-details-row'>{t('contact.address')}: {t('contact.address1')} <br/>{t('contact.address2')} </div>
							<div class = 'contact-map' style={{ height: '200px', width: '100%' }}>
						        <GoogleMapReact
						          bootstrapURLKeys={{ key: 'AIzaSyB3p-eqYbX0xDakRDS4EbimydgCLfCNRZQ' }}
						          defaultZoom={18}
						          defaultCenter={{
								      lat: 22.286152025236813, 
								      lng: 114.19270559179401
								    }}
						        >
											                  <AnyReactComponent
						            lat={22.286152025236813}
						            lng={114.19270559179401}
						            text="FSMA"
						          />
						        </GoogleMapReact>
	      				  </div>
	      			</div>
						</div>
					</div>
				</div>	
			</div>
			)
		}

export default translate(Contact);
