import React, { useState, useEffect } from 'react'
import './ArchiveItem.css'
import { CloudinaryContext, Image } from "cloudinary-react";
import Header from './Header.js'
import Footer from './Footer.js'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { Link, useHistory, useLocation } from "react-router-dom"
import { translate,getLanguage } from 'react-switch-lang';
const ArchiveItem = (props) => {
  const { t } = props;
	const moment = require('moment')
	const [threeNews, setThreeNews] = useState([{
		coverId:'',
	  	category:[],
	  	eng:{
	  		header:'',
	  		content:'',
	  		photoId:'',
	  		video:''
	  	},
	  	tra:{
	  		header:'',
	  		content:'',
	  		photoId:'',
	  		video:''
	  	},
		sim:{
	  		header:'',
	  		content:'',
	  		photoId:'',
	  		video:''
	  	}
	}])
	const [news,setNews] = useState({
  	coverId:'',
  	category:[],
  	eng:{
  		header:'',
  		content:'',
  		photoId:'',
  		video:''
  	},
  	tra:{
  		header:'',
  		content:'',
  		photoId:'',
  		video:''
  	},
	sim:{
  		header:'',
  		content:'',
  		photoId:'',
  		video:''
  	}
  })
  let data = useLocation();
  let history = useHistory();
	  const callNews = async (year) => {
	    const response = await fetch('/api/news?year='+year.toString(), {
	    method: "GET"
	  })
	    const body = await response.json();
	    if (response.status !== 200) throw Error(body.message);

	    return body;
	  };
  useEffect(() => {

    const callApi = async () => {

      const response = await fetch(`/api/getNews?news_id=${encodeURIComponent(data.state._id)}`, {
        method: "GET"
      })
      const body = await response.json();
      if (response.status !== 200) throw Error(body.message);

      return body;
    };
    callApi()
      .then(res => 
        {
          const newsBody = res.news;
          setNews(newsBody[0])
          setThreeNews(res.three_news)
        })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {

    if (typeof data.state == 'undefined'){
      history.push('/Archive')
    }
  },[data])
  const changeNews = (news) =>{
  	const callApi = async () => {

      const response = await fetch('/api/getNews?news_id='+news._id, {
        method: "GET"
      })
      const body = await response.json();
      if (response.status !== 200) throw Error(body.message);

      return body;
    };
    callApi()
      .then(res => 
        {
          const newsBody = res.news;
          setNews(newsBody[0])
          setThreeNews(res.three_news)
        })
      .catch(err => console.log(err));
  }
  const showCat = news.category.map(cat => <p class = 'archive-item-cat'><b>{t('archive.'+cat)}</b></p>)
  const showSideNews = threeNews.map(news => 
  	<div class = 'side-news pointer' onClick = {()=>changeNews(news)}> 
  		<div class = 'center-cropped'>
  				{news.eng.video==""
		  		?<Image className = 'archive-news-image' publicId={news.coverId +'.jpg'}></Image>
		  		:<iframe src={'https://www.youtube.com/embed/'+news[getLanguage()].video+'?autoplay=0&rel=0'}></iframe>
		  	}
  		</div>
  		<div>{news[getLanguage()].header}</div>
  		<div class='side-news-date'>{moment(news.date).format('MMMM Do YYYY')}</div>  	
  	</div>
  	)
	let ReactSafeHtml = require('react-safe-html');
	return (
	    <CloudinaryContext cloudName="dn5v5udu6">
	    <Header></Header>
	    <div class = 'archive-color'>
	    </div>
	    <div class = 'archive-item-container'>

			<div class = 'archive-item-first'>
			    <Link to="/archive" className='back-link pointer'> <img src='./back-arrow.png' class = 'back-arrow' />{t('archive.text1')} </Link><br />
				<div class = 'archive-item-header'>
					<div>
						<h1> {news[getLanguage()].header} </h1>
						<p class = 'archive-item-date-text'> {moment(news.date).format('MMMM Do YYYY')} </p>
					</div>
				</div>
				<div class = 'archive-item-first-container'>
					<div class = 'archive-item-image-container'>			  	
						{news[getLanguage()].video==""
					  		?<div></div>
					  		:<iframe  height="500px" width="100%" src={'https://www.youtube.com/embed/'+news[getLanguage()].video}></iframe>
					  	}
						<Image publicId={news.coverId}></Image>
						<ReactSafeHtml html={news[getLanguage()].content} />
					</div>
					<div>
						 <p><b>{t('archive.text2')}</b></p>
             <div class = 'side-news-container'>
            {showSideNews}
            </div>
						<p class = 'archive-item-cat-title'><b>{t('archive.text3')}</b></p>
						{showCat}
					</div>
				</div>
      {news[getLanguage()].photoId==""
        ?<div></div>
        :<div style = {{marginBottom:"5%"}}>
          <Image publicId={news[getLanguage()].photoId}></Image>
        </div>
      }
       {news[getLanguage()].photoArray== undefined || news[getLanguage()].photoArray.length==0
        ?<div></div>
        :<div style = {{marginBottom:"5%"}}>
          {news[getLanguage()].photoArray.map(function (item, index) {
                            return <Image publicId={item}></Image>
          })}
        </div>
      }
			</div>
		</div>
  		<Footer />
	</CloudinaryContext>
	);
}

export default translate(ArchiveItem)
