import React, { Component } from 'react'
import './Main.css'
import Donation from './components/Donation.js'
import { CloudinaryContext, Image } from "cloudinary-react";
import Header from './Header.js'
import Footer from './Footer.js'
import Contact from './components/Contact.js'
import { Link } from "react-router-dom"
import { translate,getLanguage } from 'react-switch-lang';
import PropTypes from 'prop-types';

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  learn: false,
		  donors:{
		  	eng:[],
		  	tra:[],
		  	sim:[]
		  },
		  special:[[[]],[[]],[[]],[[]],[[]],[[]]],
		  news:[{
		    coverId:'',
		    category:'',
		    eng:{
		      header:'',
		      content:'',
		      photoId:'',
		      video:''
		    },
		    tra:{
		      header:'',
		      content:'',
		      photoId:'',
		      video:''
		    },
		  sim:{
		      header:'',
		      content:'',
		      photoId:'',
		      video:''
		    }
		  }]
		};

	}

    componentWillMount() {
    this.callDonors()
      .then(res => 
        {

          const donorList = res.donors[0];
          this.setState({ donors:donorList})
        })
      .catch(err => console.log(err));
    this.callSpecial()
      .then(res => 
        {
          const specialBody = res.special
          console.log(specialBody)
          this.setState({special:specialBody})

        })
      .catch(err => console.log(err));
    this.callNews()
   	  .then(res =>
   	  {
   	  	console.log(res)
   	  	const news = res.news
   	  	console.log(news)
   	  	this.setState({news:news})
   	  })
   }

   callNews = async () => {
   	    const response = await fetch('/api/getStarredNews');
	    const body = await response.json();
	    if (response.status !== 200) throw Error(body.message);

	    return body;

   }
  
  callDonors = async () => {
    const response = await fetch('/api/donors');
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);

    return body;
  }

  callSpecial = async () => {
    const response = await fetch('/api/special');
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);

    return body;
  };



	handleLearnMore = () => {
		 this.setState({ learn:true });
	}

	closeLearnMore = () => {
		 this.setState({ learn:false });
	}
	render(){
		const { t } = this.props;
		const learn = this.state.learn
		const newsArr = this.state.news
		const moment = require('moment')
		const lang = getLanguage()
		const showNews = newsArr.map(news => 
			<div class = 'fourth-content'>
				<Link
				  to={{
				    pathname: "/ArchiveItem",
				    state: { _id: news._id}
				  }}> 
				{news[lang].video==""
			  		?<Image className = 'fourth-image' publicId={news.coverId+'.jpg'}></Image>
			  		:<iframe src={'https://www.youtube.com/embed/'+news[lang].video+'?autoplay=0&rel=0'}></iframe>
			  	}
			  	
				<div class = 'fourth-text'>{news[lang].header}</div>
				<div class = 'fourth-date'>{moment(news.date).format('MMMM DD YYYY')} </div>
				</Link>
			</div>
		  );
		return (
			    <CloudinaryContext cloudName="dn5v5udu6">
			    <Header></Header>
				<div class = 'intro'>
					<div> 
						<div>
						<img src= './intro.png' class = 'intro-img'></img>
						<img src= './intro-mobile.png' class = 'intro-mobile-img'></img>
						</div>

					</div>
					<div class = 'intro-words'>
						<div class = 'middle'>
							<p class = 'main-small'> {t('main.year')} </p>
							<div class = 'title'>
								<img src= './logo.png' class = 'main-logo'></img>
								<div class = 'title-words'>
								<h1>{t('main.title')} <br /> {t('main.title2')}  </h1>
								</div>
							</div>
							<div class = 'white-text'>							
							<div class = 'super-small-words'>{t('main.superSmallWords')}</div>
							{t('main.subTitle')} </div>
												</div>
							<div class = 'intro-footer'>
								<div class = 'main-small'> {t('main.register1')} <br /> {t('main.register2')} </div>
								<div class = 'main-small'> {t('main.patron1')} </div>
								<img src = './hkcss.png' class ='hkcss-image'></img>
							</div>
					</div>
				</div>
				<div class = 'intro-second'>
					<div class='stupid-scroll-container'>
						<div class='stupid-scroll'>SCROLL</div>
						<div class = 'stupid-scroll-line'></div>
					</div>
					<img src= './wave.png' class='intro-second-image'></img>
				</div>
				<div class = 'second'>
					<div class = 'second-sub'>
						<div>
							<div class = 'reg-dots-parent'>
								<img src= './reg-dots.png' class = 'reg-dots'></img>
							</div>
							<div class = 'second-image'>
								<img src = './sma4.png' class = 'clip-circle'></img>
							</div>
						</div>
						<div class = 'second-fluff'></div>

						<div class = 'second-words'>
							<h1> {t('main.secondTitle')} </h1>
							<p class = 'white-text'>{t('main.secondFirstParagraph')}</p>
							<p  class = 'white-text'>{t('main.secondSecondParagraph')}</p>
							<p class = 'second-link'><Link to="/sma"> {t('main.secondLearn')} <img src= './orange-arrow.png' class = 'orange-arrow'></img></Link></p>
							<div class = 'circle-dots-parent'>
								<img src= './circle-dots.png' class = 'circle-dots'></img>
							</div>
							<div class =  'ocean-wave-parent'>
								<img src= './ocean-wave.png' class = 'ocean-wave'></img>
							</div>
						</div>
					</div>
				</div>
				<div class = 'third'>
					<div class = 'third-sub'>
						<p class = 'third-title'>
							{t('main.thirdTitle')}
						</p>
						<p class = 'third-text'>{t('main.thirdText')}</p>
						{learn
							?<>
								 <div class = 'third-container'>
									<div class = 'third-content'>
										<img src = './about1.png' class = 'third-circle'></img>
										{t('main.third1')}
									</div>
									<div class = 'third-content'>
										<img src = './about2.png' class = 'third-circle'></img>
										{t('main.third2')}
									</div>
									<div class = 'third-content'>
										<img src = './about3.png' class = 'third-circle'></img>
										{t('main.third3')}
									</div>
									<div class = 'third-content'>
										<img src = './about4.png' class = 'third-circle'></img>
										{t('main.third4')}
									</div>
									<div class = 'third-content'>
										<img src = './about5.png' class = 'third-circle'></img>
										{t('main.third5')}
									</div>
									<div class = 'third-content'>
										<img src = './about6.png' class = 'third-circle'></img>
										{t('main.third6')}
									</div>
									<div class = 'third-content'>
										<img src = './about7.png' class = 'third-circle'></img>
										{t('main.third7')}
									</div>
									<div class = 'third-content'>
										<img src = './about8.png' class = 'third-circle'></img>
										{t('main.third8')}
									</div>
									<div class = 'third-content'>
										<img src = './about9.png' class = 'third-circle'></img>
										{t('main.third9')}
									</div>
								  </div>
							  	  <a onClick={this.closeLearnMore} class = 'third-link pointer'> {t('main.thirdClose')} <img src ='index-up-arrow.png' class = 'index-up-arrow' /> </a> 
							  </>															
							: <div onClick={this.handleLearnMore} class = 'third-link pointer'> {t('main.thirdLearn')} <img src ='index-down-arrow.png' class = 'index-down-arrow' /></div>
						}
					</div>
				</div>
				<div class = 'fourth'>
					<div class = 'fourth-sub'>
						<p class = 'fourth-title'>
							{t('main.fourthTitle')}
						</p>
						<div class = 'fourth-container'>
							{showNews}
						</div>
						<Link to = './Archive' class = 'fourth-link'><div class = 'fourth-link-container'> <div>{t('main.fourthLink')} <img src ='white-arrow.png' class = 'white-arrow' /></div> </div> </Link>
					</div>
				</div>	
				<div class = 'seventh'>
					<div class = 'seventh-title-container'>
						<p class = 'seventh-title'>
							{t('main.seventhTitle')}
						</p>
						<Donation></Donation>
					</div>
				</div>
				<Contact color = 'yellow'/>
				<Footer />

			</CloudinaryContext>
			)
	}
}

Main.propTypes = {
  t: PropTypes.func.isRequired,
};
 
export default translate(Main);
