import React from 'react';
import { Link } from 'react-router-dom';


const Unauthorized = () => {
  return (
    <div className = 'landing-container'>
      <div className="message">
        <h1>FSMA CMS</h1>
        <h3>You are signed out</h3>
        <p>Please login to access the FSMA's CMS</p>      
      </div>
      <p><Link to='/cms/login'>Back to Login</Link></p>
    </div>
  )
}

export default Unauthorized;