import React from 'react';
import './Confirmation.css'
function Confirmation(props) {

  return (
    <>
      {props.show && 
        <div class = 'confirmation-container'>
          <p class ='checkmark'>✓</p>
          <p class = 'sentence'>{props.sentence}</p>
        </div>
      }
    </>
  );
}

export default Confirmation