import React, {useState} from 'react';
import { Link, useHistory } from "react-router-dom"
import { CloudinaryContext, Image } from "cloudinary-react";
import './NewAppreciation.css'
import { openUploadWidget } from "../CloudinaryService";
import Confirmation from '../components/Confirmation.js'
const NewAppreciation = () => {
  let history = useHistory();
  const [note, setNote] = useState({
  	coverId:'',
  	eng:{
  		author:'',
  		details:'',
  		content:''
  	},
  	tra:{
  		author:'',
  		details:'',
  		content:''
  	},
	sim:{
  		author:'',
  		details:'',
  		content:''
  	}
  })
  const [show, setShow] = useState(false)

  const handleChange = (event) =>{
  	if([event.target.id] == 'eng'){
	  	setNote({
	  		...note,
	  		eng:{
	  			...note.eng,
		  		[event.target.name]:event.target.value
	  		}
	  	})
	}
	if([event.target.id] == 'tra'){
	  	setNote({
	  		...note,
	  		tra:{
	  			...note.tra,
		  		[event.target.name]:event.target.value
	  		}
	  	})
	}
	if([event.target.id] == 'sim'){
	  	setNote({
	  		...note,
	  		sim:{
	  			...note.sim,
		  		[event.target.name]:event.target.value
	  		}
	  	})
	}
	console.log(note)
  }

  const handleSubmit = async public_id => {
    const response = await fetch('/api/addnote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({note: note}),
    })
    const body = await response.json()
    setShow(true);
    const timerId = setInterval(() => {
      setShow(false)
    }, 2000);

  };

  const beginUpload = tag => {
	  const uploadOptions = {
	    cloudName: "dn5v5udu6",
	    tags: [tag, 'anImage'],
	    uploadPreset: "upload"
	  };


	  openUploadWidget(uploadOptions, (error, photos) => {
	    if (!error) {
	      console.log(photos);
	      if(photos.event === 'success'){
	        setNote({
	        	...note,
	        	coverId:photos.info.public_id
	        	})

	      }
	    } else {
	      console.log(error);
	    }
	  })
	}

  return (
    <CloudinaryContext cloudName="dn5v5udu6">
    	<Confirmation show = {show} sentence = 'Note Uploaded'/>
    	<div class = 'na-container'>
        	<Link to="/cms" class = 'home-link'> Back Home </Link>
        	 <br />
        	<Link to="/cms/manageappreciation" class = 'home-link'> Manage Notes of Appreciation </Link>
	        	<h2> Add New Notes of Appreciation </h2>
	        <div class = 'na-subcontainer'>
	        	<div class = 'na-box'>
	        		<div class = 'na-language-box'>
	        			<b>English version</b>
	        			<p> Author/Patient Name</p>
						<textarea id = 'eng' name='author' class = 'na-author-ta' placeholder='Example: Ma Chik Yiu, Elaine' value={note.eng.author} onChange={handleChange} />
	        			<p> Author/Patient Details</p>
						<textarea id = 'eng' name='details' class = 'na-details-ta' placeholder='Example: (Ma Chik Yiu - Type 2, Adult)' value={note.eng.details} onChange={handleChange} />
	        			<p> Content</p>
						<textarea id = 'eng' name='content' class = 'na-note-ta' value={note.eng.content} onChange={handleChange} />
					        
	        		</div>

	        	</div>
	        	<div class = 'na-cover-box'>
		        		<b>Cover Picture</b>
			                  <button onClick={() => beginUpload(4)} class= 'na-image-button'>Upload Image</button>
		        		<p> A 4:3 ratio photo is preferred for the cover photo.</p>
		        		<p> Current Cover Picture</p>
		        		<div className="na-image-box">
			                <Image publicId={note.coverId}></Image>


			        	</div>
		        </div>
	        </div>
	        <div class = 'na-subcontainer'>
	        	<div class = 'na-box'>	        	
	        		<div class = 'na-language-box'>
	        			<b>Traditional Chinese version</b>
	        			<p> Author/Patient Name</p>
						<textarea id = 'tra' name='author' class = 'na-author-ta' placeholder='Example: Ma Chik Yiu, Elaine' value={note.tra.author} onChange={handleChange} />
	        			<p> Author/Patient Details</p>
						<textarea id = 'tra' name='details' class = 'na-details-ta' placeholder='Example: (Ma Chik Yiu - Type 2, Adult)' value={note.tra.details} onChange={handleChange} />
	        			<p> Content</p>
						<textarea id = 'tra' name='content' class = 'na-note-ta' value={note.tra.content} onChange={handleChange} />
					        
	        		</div>
	        	</div>
	        </div>
	       	<div class = 'na-subcontainer'>
	        	<div class = 'na-box'>
	        		<div class = 'na-language-box'>
	        			<b>Simplified Chinese version</b>
	        			<p> Author/Patient Name</p>
						<textarea id = 'sim' name='author' class = 'na-author-ta' placeholder='Example: Ma Chik Yiu, Elaine' value={note.sim.author} onChange={handleChange} />
	        			<p> Author/Patient Details</p>
						<textarea id = 'sim' name='details' class = 'na-details-ta' placeholder='Example: (Ma Chik Yiu - Type 2, Adult)' value={note.sim.details} onChange={handleChange} />
	        			<p> Content</p>
						<textarea id = 'sim' name='content' class = 'na-note-ta' value={note.sim.content} onChange={handleChange} />
					        
	        		</div>	 
	        	</div>  

	        </div>
	        <button onClick = {handleSubmit}>submit</button>
	    </div>
    </CloudinaryContext>
  );
}
export default NewAppreciation