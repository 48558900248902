import React, {useState, useEffect} from 'react';
import { Link, useHistory } from "react-router-dom"
import { CloudinaryContext, Image } from "cloudinary-react";
import './UpdateChart.css'
import { openUploadWidget } from "../CloudinaryService";
import Confirmation from '../components/Confirmation.js'
const UploadChart = () => {
	const [charts, setCharts] = useState(
	{

			eng:{
				coverId:''
			},
			tra:{
				coverId:''
			},
			sim:{
				coverId:''
			}

	})
	const [show, setShow] = useState('')
	const handleSubmit = async () => {
    const response = await fetch('/api/addchart', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({charts:charts }),
    })
    const body = await response.json()
    setShow(true);
    const timerId = setInterval(() => {
      setShow(false)
    }, 2000);

  };

  const beginUpload = tag => {
  	  console.log(charts)
	  const uploadOptions = {
	    cloudName: "dn5v5udu6",
	    tags: [tag, 'anImage'],
	    uploadPreset: "upload"
	  };


	  openUploadWidget(uploadOptions, (error, photos) => {
	    if (!error) {
	      if(photos.event === 'success'){
	      	let newCharts = { 
	      		...charts,
		  		[tag]:{
		  			coverId:photos.info.public_id
		  		 }
		  	}
		  	delete newCharts['_id'];	 	
		  	console.log(newCharts)
	        setCharts(newCharts)
	    } else {
	      console.log(error);
	    }
	  }
	  }
	  )
}

	useEffect(() => {

	    const callApi = async () => {

	      const response = await fetch('/api/getChart')
	      const body = await response.json();
	      if (response.status !== 200) throw Error(body.message);

	      return body;
	    };
	    callApi()
	      .then(res => 
	        {
	          const chartBody = res.chart;
	          console.log(chartBody)
	          setCharts(chartBody[0])
	        })
	      .catch(err => console.log(err));
	  }, []);


  return (
    <CloudinaryContext cloudName="dn5v5udu6">
    	<Confirmation show = {show} sentence = 'Chart Uploaded'/>
    	<div class = 'uc-container'>
        	<Link to="/cms" class = 'home-link'> Back Home </Link>
	        	<h2> Update Expenditure Chart </h2>
	        	<div class = 'uc-cover-box'>
	        		<b>English Version</b>
		                  <button onClick={() => beginUpload('eng')} class= 'uc-image-button'>Upload Chart</button>
	        		<p> Current Chart</p>
	        		<div className="uc-image-box">

	        			<Image publicId={charts.eng.coverId}></Image>
		         	</div>
	        	</div>

	        	<div class = 'uc-cover-box'>
	        		<b>Traditional Chinese Version</b>
		                  <button onClick={() => beginUpload('tra')} class= 'uc-image-button'>Upload Chart</button>
	        		<p> Current Chart</p>
	        		<div className="uc-image-box">
	        			<Image publicId={charts.tra.coverId}></Image>
		         	</div>
	        	</div>
	        	<div class = 'uc-cover-box'>
	        		<b>Simplified Chinese Version</b>
		                  <button onClick={() => beginUpload('sim')} class= 'uc-image-button'>Upload Chart</button>
	        		<p> Current Chart</p>
	        		<div className="uc-image-box">
		                <Image publicId={charts.sim.coverId}></Image>
	        			
	        		
		         	</div>
	        	</div>
	        <button onClick={handleSubmit} class= 'uc-image-button'>Submit Changes</button>
	    </div>

    </CloudinaryContext>
  );
}
export default UploadChart