import React, { useState, useEffect } from 'react'
import './Donate.css'
import Donation from './components/Donation.js'
import Contact from './components/Contact.js'
import Header from './Header.js'
import Footer from './Footer.js'
import { translate,getLanguage } from 'react-switch-lang';
const Donate = (props) => {
	const { t } = props;

 
	return (
	    <div>
		    <Header></Header>
			<div class = 'donate'>
				<div class ='donate-sub'>
					<div class = 'donate-text'>
						<h1>{t('donation.title')}</h1>
					</div>
					<Donation />
				</div>
			</div>
			<Contact/>
			<Footer />
		</div>
	);
}

export default translate(Donate);
