import React, {useState, useEffect, useRef} from 'react';

import { Link } from "react-router-dom"
import './Footer.css'

import {
  translate
} from 'react-switch-lang';

const Footer = (props) => {
	const { t } = props;
	return (
		<div class = 'footer'>
			<div class = 'footer-links'>
			  <Link to="/">{t('footer.work')} </Link> 
		      <Link to="/archive">{t('footer.archive')} </Link>  
		      <Link to="/contact">{t('footer.contact')}</Link> 
		      </div>
		      <div class = 'footer-links'>  
		      <Link to="/about">{t('footer.about')}</Link>  
		      <Link to="/involved">{t('footer.involved')}</Link>
		      <Link to="/donate">{t('footer.donate')}</Link>  
		      <Link to="/privacy">{t('footer.privacy')}</Link>  
		      </div>
		      <div class = 'footer-links'>
		        <p>{t('contact.tel')}:  2811 1767 </p>
		        <p>{t('contact.fax')} :  2510 7199 </p>
		        <p>info@fsma.org.hk </p>
		        <p>{t('contact.address1')} </p>
		        <p>{t('contact.address2')}</p>
		      </div>
		      <div class = 'footer-icon-rows'>
		      <a href = 'https://www.facebook.com/fsma.org.hk/'><img src = './fb.png' class = 'footer-icon' /></a>
		      <a href = 'https://www.youtube.com/channel/UC7Mt_n3mT12CndpfuuTBJpQ'><img src = './youtube.png' class = 'footer-icon' /></a>
		      <br /><br />
		      <p>{t('contact.copyright')}</p>
		      </div>
		</div>
			      
		);
}

export default translate(Footer);
