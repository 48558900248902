import React, { useState, useEffect } from 'react'
import './Archive.css'
import { CloudinaryContext, Image } from "cloudinary-react";
import Header from './Header.js'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { Link, useHistory } from "react-router-dom"
import Footer from './Footer.js'
import Contact from './components/Contact.js'
import { translate,getLanguage } from 'react-switch-lang';
import Dropdown from 'react-bootstrap/Dropdown'
const Archive = (props) => {
	const { t } = props;
	const [showYears,setShowYears] = useState(true)
	const [currentCat,setCurrentCat] = useState('Categories')
	const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
	const [news,setNews] = useState([{
  	coverId:'',
  	category:'',
  	eng:{
  		header:'',
  		content:'',
  		photoId:'',
  		video:''
  	},
  	tra:{
  		header:'',
  		content:'',
  		photoId:'',
  		video:''
  	},
	sim:{
  		header:'',
  		content:'',
  		photoId:'',
  		video:''
  	}
  }])


	const moment = require('moment')
	function range(start, end) {
	  return Array(end - start + 1).fill().map((_, idx) => start + idx).sort((a,b)=>b-a)
	}
	const changeYear= (year) => {
		setCurrentYear(year)
		callNews(year)
    	.then (res =>
    	{
    		setNews(res.news)
    		console.log(res.news)
    	})
    	.catch(err => console.log(err));
	}


	  const callNewsCat = async (cat) => {
	    const response = await fetch('/api/newsByCat?cat='+cat, {
	    method: "GET"
	  })

	    const body = await response.json();
	    if (response.status !== 200) throw Error(body.message);

	    return body;
	  };


	  const handleCat = (cat) =>{
	  	callNewsCat(cat)
	  	.then (res =>
	    	{
	    		setNews(res.news)
	    		setCurrentCat(cat)
	    		setShowYears(false)
	    	})
		
	}
	const yearsArray = range(1998,new Date().getFullYear())
	const yearToggle = yearsArray.map((year) =>
		<ToggleButton variant = 'asdf' class = 'archive-tb' key= {year ||""} onClick={()=>changeYear(year)}>
		{
			year==currentYear
			?<b>{year}</b>
			:year
		}</ToggleButton>
	);
	  const callNews = async (year) => {
	    const response = await fetch('/api/news?year='+year.toString(), {
	    method: "GET"
	  })
	    const body = await response.json();
	    if (response.status !== 200) throw Error(body.message);

	    return body;
	  };

	  const handleDefaultCat = () =>{
	  	callNews(currentYear).then (res =>
	    	{
	    		setNews(res.news)
	    		setShowYears(true)
	    		setCurrentCat('Categories')	    	})
	    	.catch(err => console.log(err));
	  }
	useEffect(() => {
	  callNews(currentYear)
	    	.then (res =>
	    	{
	    		setNews(res.news)
	    	})
	    	.catch(err => console.log(err));
	  }, []); 
	const showNews = news.map(news => 
		<div class = 'pointer archive-news-container'>
			<Link
			  to={{
			    pathname: "/ArchiveItem",
			    state: { _id: news._id}
			  }}> 
			  	{news[getLanguage()].video==""
			  		?<Image className = 'archive-news-image' publicId={news.coverId+'.jpg'}></Image>
			  		:<iframe src={'https://www.youtube.com/embed/'+news[getLanguage()].video+'?autoplay=0&rel=0'}></iframe>
			  	}
			  		<div class = 'archive-first-text-container'>
					<div class = 'archive-first-text'>{news[getLanguage()].header}</div>
					{getLanguage()=='eng'
						?<div class = 'archive-first-date'>{moment(news.date).format('MMMM DD YYYY')} </div>
						:<div class = 'archive-first-date'>{moment(news.date).format('DD/MM/YYYY')} </div>
					}
					</div>
			</Link>
		</div>
	  );

	const yearDropdownItem = yearsArray.map (year =><Dropdown.Item onClick={()=>changeYear(year)}>{year}</Dropdown.Item>);
 
	return (
	    <CloudinaryContext cloudName="dn5v5udu6">
	    <Header></Header>
	    <div class = 'archive-color'>
	    </div>
	    <div class = 'archive-container'>
	    	<div class = 'archive-sub'>
			<div class = 'archive-first'>
				<div class = 'archive-title-row'>
					<h1>{t('archive.title')}</h1>
					<Dropdown className = 'cat-dropdown'>
					  <Dropdown.Toggle variant="categories" id="dropdown-basic">
					    <div>{t('archive.'+currentCat)}</div>
					  </Dropdown.Toggle>

					  <Dropdown.Menu>
					    <Dropdown.Item onClick={()=>handleCat("New Drug Information")}>{t("archive.New Drug Information")}</Dropdown.Item>
					    <Dropdown.Item onClick={()=>handleCat("History and Track Record")}>{t("archive.History and Track Record")}</Dropdown.Item>
					    <Dropdown.Item onClick={()=>handleCat("Public Awareness")}>{t("archive.Public Awareness")}</Dropdown.Item>
					 	<Dropdown.Item onClick={()=>handleCat("Member Activities")}>{t("archive.Member Activities")}</Dropdown.Item>
					    <Dropdown.Item onClick={()=>handleCat("Fund-raising Activities")}>{t("archive.Fund-raising Activities")}</Dropdown.Item>
					    <Dropdown.Item onClick={handleDefaultCat}>{t("archive.all")}</Dropdown.Item>
					  </Dropdown.Menu>
					</Dropdown>
					{showYears
					?<Dropdown className = 'year-dropdown'>
					  <Dropdown.Toggle variant="categories" id="dropdown-basic">
					    <div>Years</div>
					  </Dropdown.Toggle>

					  <Dropdown.Menu>
					   	{yearDropdownItem}
					  </Dropdown.Menu>
					</Dropdown>
					:<div></div>
					}
				</div>
				<div class = 'archive-first-date-container'>
					<div class = 'archive-first-container'>
						{showNews}
					</div>
					{showYears
						?<ToggleButtonGroup className = 'btn-group-vertical' type="checkbox" title="Choose A Category" >
							{yearToggle}
						</ToggleButtonGroup>
						:<div></div>
					}
				</div>
			</div>
			</div>
		</div>
		<Footer />
	</CloudinaryContext>
	);
}

export default translate(Archive);
