import React, { Component } from 'react';
import { Link } from "react-router-dom"
import './Donor.css'
import Confirmation from '../components/Confirmation.js'
class Donor  extends Component {
  constructor(props) {
    super(props);
    this.state = {
    	eng:'',
      engNamesSplit:[],
      tra:'',
      traNamesSplit:[],
      sim:'',
      simNamesSplit:[]  }
}

  
  componentWillMount() {
    this.callApi()
      .then(res => 
        {
          console.log(res)
          const eng = res.donors[0].eng.join("\n");
          const tra = res.donors[0].tra.join("\n");
          const sim = res.donors[0].sim.join("\n");
          const engNamesSplit = res.donors[0].eng;
          const traNamesSplit = res.donors[0].tra;
          const simNamesSplit = res.donors[0].sim;
          this.setState({ eng:eng,tra:tra,sim:sim,engNamesSplit:engNamesSplit,traNamesSplit:traNamesSplit,simNamesSplit:simNamesSplit})
        })
      .catch(err => console.log(err));
  }
  
  callApi = async () => {
    const response = await fetch('/api/donors');
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);

    return body;
  };
  
  handleSubmit = async e => {
    e.preventDefault();
    const response = await fetch('/api/adddonor', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ eng: this.state.engNamesSplit, tra:this.state.traNamesSplit, sim:this.state.simNamesSplit }),
    });
    const body = await response.text();
    this.setState({ 
      show: true 
    });
    setInterval(() => {
      this.setState({show:false});
    }, 3000);
  };

  handleChange(event) {
  	let namesSplit = event.target.value.split(/\r?\n/);
    if ([event.target.name] == 'eng'){
        console.log('eng')
        this.setState({
          engNamesSplit: namesSplit,
          eng: event.target.value});
    }
    if ([event.target.id] == 'tra'){
              console.log('tra')
    	this.setState({
    		traNamesSplit: namesSplit,
    		tra: event.target.value});
    }
    if ([event.target.id] == 'sim'){
              console.log('sim')
      this.setState({
        simNamesSplit: namesSplit,
        sim: event.target.value});
    }
    console.log(this.state.engNamesSplit)
    console.log(this.state.traNamesSplit)
  }

render() {
	const donors = this.state.response
    return (
        <div class = 'donor-container'>
          <Confirmation show = {this.state.show} sentence = 'Information of Donors has been updated'/>
        	<Link to="/cms" class = 'home-link'> Back Home </Link>
	        	<h2> Edit Donor Names </h2>
			    	<div class = 'donor-subcontainer'>
			    		<div>
					    	<h4> English Version </h4>
						          <textarea id = 'eng' name = 'eng' class = 'donor-ta' placeholder='Type the donor names' value={this.state.eng} onChange={(e)=>this.handleChange(e)} />
				        </div>
				        <div>
					    	<h4> Traditional Chinese Version</h4>

						          <textarea id='tra' name = 'tra' class = 'donor-ta' placeholder='Type the donor names' value={this.state.tra} onChange={(e)=>this.handleChange(e)} />
				        </div>
				        <div>
					    	<h4> Simplified Chinese Version </h4>

					         		 <textarea id = 'sim' name = 'sim' class = 'donor-ta' placeholder='Type the donor names' value={this.state.sim} onChange={(e)=>this.handleChange(e)} />
				        </div>
			        </div>
          <button onClick = {this.handleSubmit}>submit</button>
        </div>
    );
  }
}

export default Donor;