import React, {useState, useEffect, useMemo} from 'react';
import { CloudinaryContext, Image } from "cloudinary-react";
import { Link } from "react-router-dom"
import './Volunteer.css'
import { openUploadWidget } from "../CloudinaryService";
import Confirmation from '../components/Confirmation.js'
const Volunteer = () => {
  const [show, setShow] = useState(false)
 const [showFormArr, setShowFormArr] = useState([]);
  const [forms, setForms] = useState ({
		individual:'',
		organization:'',
		volunteerArr:[
			{
			pdfId:'',
			originalFilename:'',
			lang:'eng'
			},
			{
			pdfId:'',
			originalFilename:'',
			lang:'tra'
			},
			{
			pdfId:'',
			originalFilename:'',
			lang:'sim'
			}
		]
	})

  const callApi = async () => {
	    const response = await fetch('/api/volunteerForms', {
        method: "GET"
      })
	    const body = await response.json();
	    if (response.status !== 200) throw Error(body.message);

	    return body;
	  };

	useEffect(() => {
	    callApi()
	      .then(res => 
	        {
	        console.log(res)
	          const formsBody = res.forms[0];
	          console.log(formsBody)
	          setForms(formsBody)
	          console.log(forms)
	        })
	      .catch(err => console.log(err));
	}, []);
  
   function FormsList(props) {
   	const formsArr = props.forms;
   	const handleShowForm = (idx) => {
   		beginUpload(idx)
		//update Form here 
    }
    const listForms = useMemo(() => formsArr.map((form, idx)=>
	  	<div class = 'form-row' key = {idx}>
	  		<div>
	  		<b>{form.formValue}</b>
	  		</div> 
			{showFormArr.includes(idx)? <p>Uploaded Form!</p>
				:<p><a href = {'https://res.cloudinary.com/dn5v5udu6/image/upload/fl_attachment/'+form.pdfId+'.pdf'}>Current form: {form.originalFilename}.pdf</a></p>
			}
			<button class = 'volunteer-replace-btn' onClick = {()=>handleShowForm(idx)}>Replace Form </button>
		</div>
    ),[showFormArr]);   	
    return (<div>{listForms}</div>)
  }

  const handleFormChange = (event) =>{
  	setForms({ 
  		...forms,
  		[event.target.name]:event.target.value
  		})
  }

  const updateVolunteerLink = async (cat) => {
  	    const response = await fetch('/api/editVolunteerLinks', {
	      method: 'POST',
	      headers: {
	        'Content-Type': 'application/json',
	      },
	      body: JSON.stringify({form_id: forms._id, category:cat, individual: forms.individual, organization: forms.organization}),
	    })
	    const body = await response.json()
	    setShow(true);
	    const timerId = setInterval(() => {
	      setShow(false)
	    }, 2000);

	  };

  const updateVolunteerForm = async (idx,pdf) => {
  	 const response = await fetch('/api/editVolunteerForms', {
	      method: 'POST',
	      headers: {
	        'Content-Type': 'application/json',
	      },
	      body: JSON.stringify({form_id:forms._id,idx: idx, pdf:pdf}),
	    })
	    const body = await response.json()
	    setShow(true);
	    const timerId = setInterval(() => {
	      setShow(false)
	    }, 2000);
 
  }
  const beginUpload = idx => {
	  const uploadOptions = {
	    cloudName: "dn5v5udu6",
	    tags: ['pdf'],
	    uploadPreset: "upload"
	  };


	  openUploadWidget(uploadOptions, (error, pdf) => {
	    if (!error) {
	      if(pdf.event === 'success'){
	      	console.log(pdf.info)
	      	setShowFormArr([...showFormArr, idx])
	      	updateVolunteerForm(idx,pdf.info)

	      }
	    } else {
	      console.log(error);
	    }
	  })
	}

  return (
    <CloudinaryContext cloudName="dn5v5udu6">
    	<Confirmation show = {show} sentence = 'PDF Uploaded'/>
    	<div class = 'volunteer-cms-container'>
        	<Link to="/cms" class = 'home-link'> Back Home </Link>
	        	<h2> Change Volunteer Forms </h2>
	        <div class = 'volunteer-cms-subcontainer'>
	        	<div class = 'volunteer-box'>
	        		<p><b> Volunteer registration form (individual applicant)</b></p>
	        		 <textarea name = 'individual' class = 'volunteer-ta' value={forms.individual} onChange={handleFormChange} />
	        		 <button class = 'volunteer-link-btn' onClick = {()=>updateVolunteerLink('individual')}>Replace Form </button>
	        		<p><b> Volunteer registration form (organization)</b></p>
	        		 <textarea name = 'organization' class = 'volunteer-ta' value={forms.organization} onChange={handleFormChange} />
	        		 <button class = 'volunteer-link-btn' onClick = {()=>updateVolunteerLink('organization')}>Replace Form </button>

	        		 <FormsList forms={forms.volunteerArr} />	
	        	</div>
	        </div>
	    </div>
    </CloudinaryContext>
  );
}
export default Volunteer