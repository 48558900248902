import React, {useState, useEffect} from 'react';
import { Link, useHistory, Redirect, useLocation } from "react-router-dom"
import { CloudinaryContext, Image } from "cloudinary-react";
import './AddCharityItem.css'
import { openUploadWidget } from "../CloudinaryService";
import Confirmation from '../components/Confirmation.js'
const EditCharityItem = () => {
  let history = useHistory();

  let data = useLocation();
  const [item, setItem] = useState({
  	coverId:['','','',''],
  	price:'',
  	eng:{
  		name:'',
  		tag:'',
  		desc:'',
  	},
  	tra:{
  		name:'',
  		tag:'',
  		desc:''
  	},
  	sim:{
  		name:'',
  		tag:'',
  		desc:''
  	}

  })
  const [show, setShow] = useState(false)
  useEffect(() => {

	    const callApi = async () => {

	      const response = await fetch(`/api/getCharityItem?item_id=${encodeURIComponent(data.state._id)}`, {
	        method: "GET"
	      })
	      const body = await response.json();
	      if (response.status !== 200) throw Error(body.message);

	      return body;
	    };
	    callApi()
	      .then(res => 
	        {
	          const itemBody = res.item[0];
	          setItem(itemBody)
	        })
	      .catch(err => console.log(err));
	  }, []);

   const handleChange = (event) =>{
  	setItem({ 
  		...item,
  		[event.target.id]:{
  			...item[event.target.id],
  			[event.target.name]:event.target.value
  		}
  	})
  	console.log(item)
  }

  const handlePriceChange = (event) =>{
  	 setItem({ 
  		...item,
  		price:event.target.value
  	})
  }

  const handleSubmit = async public_id => {
    const response = await fetch('/api/editCharityItem', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({item:item}),
    })
    const body = await response.json()
    setShow(true);
    const timerId = setInterval(() => {
      setShow(false);
    }, 1000);

  };

  const beginUpload = tag => {
	  const uploadOptions = {
	    cloudName: "dn5v5udu6",
	    tags: ['anImage'],
	    uploadPreset: "upload"
	  };


	  openUploadWidget(uploadOptions, (error, photos) => {
	    if (!error) {
	      if(photos.event === 'success'){
	      	let newCoverArr = [...item.coverId]
	      	newCoverArr[tag] = photos.info.public_id
	      	setItem({ 
		  		...item,
		  		coverId: newCoverArr
		  	  })

	      }
	    } else {
	      console.log(error);
	    }
	  })
	}

  return (
    <CloudinaryContext cloudName="dn5v5udu6">
    	<Confirmation show = {show} sentence = 'Item Uploaded'/>
    	<div class = 'ac-container'>
        	<Link to="/cms" class = 'home-link'> Back Home </Link> <br />
        	<Link to="/cms/managecharityitems" class = 'home-link'> Manage Charity Items </Link>
	        	<h2> Edit Charity Item </h2>
	        <div class = 'ac-subcontainer'>
	        	<div class = 'ac-box-pictures'>
	        			<b>Item photos</b>
	        			<p> Please upload 2-4 1:1 size photos to show the front, side and back views of the product.</p>
						<p> The 1st photo will be the cover photo.</p>
						<div class = 'ac-pictures-row'>
							<div class = 'ac-cover-box'>
								<div className="ac-image">
					                <Image publicId={item.coverId[0]}></Image>
					             </div>
							 	<button onClick = {()=> beginUpload(0)}>Upload photo</button>
							</div>
							<div class = 'ac-cover-box'>
								<div className="ac-image">
					                <Image publicId={item.coverId[1]}></Image>
					             </div>
							 	<button onClick = {()=> beginUpload(1)}>Upload photo</button>
							</div>
							<div class = 'ac-cover-box'>
								<div className="ac-image">
					                <Image publicId={item.coverId[2]}></Image>
					             </div>
							 	<button onClick = {()=> beginUpload(2)}>Upload photo</button>
							</div>
							<div class = 'ac-cover-box'>
								<div className="ac-image">
					                <Image publicId={item.coverId[3]}></Image>
					             </div>
							 	<button onClick = {()=> beginUpload(3)}>Upload photo</button>
							</div>					        
	        		</div>
	        	</div>
	        	<div class = 'ac-box-price'>
	        			<p><b>Price</b></p>
	        			<textarea name = 'price' class = 'ac-ta' value={item.price} onChange={handlePriceChange} />
	        	</div>
	        	<div class = 'ac-box-item'>
	        			<h5> English Info </h5>
	        			<p><b>Item Name</b></p>
	        			<textarea id = 'eng' name = 'name' class = 'ac-ta' value={item.eng.name} onChange={handleChange} />
	        			<p><b>Tag Name</b></p>
	        			<textarea id = 'eng' name = 'tag' class = 'ac-ta' value={item.eng.tag} onChange={handleChange} />
	        		    <p><b>Item Description</b></p>
	        			<textarea id = 'eng' name = 'desc' class = 'ac-desc-ta' value={item.eng.desc} onChange={handleChange} />
	        			<h5> Traditional Chinese </h5>
	        			<p><b>Item Name</b></p>
	        			<textarea id = 'tra' name = 'name' class = 'ac-ta' value={item.tra.name} onChange={handleChange} />
	        			<p><b>Tag Name</b></p>
	        			<textarea id = 'tra' name = 'tag' class = 'ac-ta' value={item.tra.tag} onChange={handleChange} />
	        		    <p><b>Item Description</b></p>
	        			<textarea id = 'tra' name = 'desc' class = 'ac-desc-ta' value={item.tra.desc} onChange={handleChange} />
	        			<h5> Simplified Chinese </h5>
	        			<p><b>Item Name</b></p>
	        			<textarea id = 'sim' name = 'name' class = 'ac-ta' value={item.sim.name} onChange={handleChange} />
	        			<p><b>Tag Name</b></p>
	        			<textarea id = 'sim' name = 'tag' class = 'ac-ta' value={item.sim.tag} onChange={handleChange} />
	        		    <p><b>Item Description</b></p>
	        			<textarea id = 'sim' name = 'desc' class = 'ac-desc-ta' value={item.sim.desc} onChange={handleChange} />
	        	</div>
	        </div>
	        <button onClick = {handleSubmit}>Update Charity Item</button>
	    </div>
    </CloudinaryContext>
  );
}
export default EditCharityItem