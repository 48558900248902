import React, {useState, useEffect, useRef} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { NavLink, Link, useLocation } from "react-router-dom"
import PropTypes from 'prop-types';

import './Header.css'

import {
  setLanguage,
  setDefaultLanguage,
  setLanguageCookie,
  translate,
  getLanguage
} from 'react-switch-lang';

 

const Header = (props) => {
 	const [navBackground, setNavBackground] = useState(false)
 	const [activeAbout,setActiveAbout] = useState(false)
    const navRef = useRef()
    navRef.current = navBackground
    const location = useLocation();
    useEffect(()=>{
      console.log(location.pathname)
      if(location.pathname == '/about' || location.pathname=='/archive' || location.pathname=='/contact'){
      	console.log(location.pathname)
      	setActiveAbout(true)
      }
      else
      {
      	setActiveAbout(false)
      }
  },[location])
    useEffect(() => {
      const handleScroll = () => {
        const show = window.scrollY > 50
        if (navRef.current !== show) {
          setNavBackground(show)
        }
      }
      document.addEventListener('scroll', handleScroll)
      return () => {
        document.removeEventListener('scroll', handleScroll)
      }
    }, [])
    const { t } = props;

    const handleSetLanguage = (key) => {
	    setLanguage(key);
	    setLanguageCookie();
	};

  const [expanded, setExpanded] = useState(false);

	return (
			<Navbar expanded={expanded} expand="lg" fixed="top" style={{ transition: '2s ease',  backgroundColor: navBackground ? 'white' : 'transparent'}} >
			<Navbar.Brand className = 'navbar-image'><Link to="/"><img src= './logo.png' class = 'header-logo' style ={{display: navBackground ?'none': 'block'}}></img></Link></Navbar.Brand>        <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} />
			         <NavLink to="/donate" className = 'mobile-donate'><Button variant="donation2">{t('header.donate')}</Button></NavLink>
        <Navbar.Collapse >
			    <Nav className="justify-content-end sma-nav" >
            <div onClick={() => setExpanded(false)}><img src= './x.png' class = 'header-x'></img></div>
			      <NavLink exact to="/" activeClassName="active-link">{t('header.home')} </NavLink>
			      <NavDropdown title={t('header.about')} id="basic-nav-dropdown" className = {activeAbout?'active-about-class':'notactive-about-class'}>
			        <NavDropdown.Item><NavLink to="/about"> {t('header.about2')}</NavLink> </NavDropdown.Item>
			        <NavDropdown.Item><NavLink to="/archive" activeClassName="active-link">{t('header.archive')}</NavLink> </NavDropdown.Item>
			        <NavDropdown.Item><NavLink to="/contact" activeClassName="active-link">{t('header.contact')}</NavLink> </NavDropdown.Item>
			      </NavDropdown>
			      <NavLink to="/sma" activeClassName="active-link">{t('header.sma')}</NavLink>
			      <NavLink to="/involved" activeClassName="active-link">{t('header.involved')}</NavLink>
			      <NavLink to="/donate" className = 'lg-donate'><Button variant="donation2">{t('header.donate')}</Button></NavLink>
			      {getLanguage()=='eng'
			      	?<NavDropdown drop = "left" title="Eng" id="basic-nav-dropdown" style = {{marginTop:"-9px"}}>
				        <NavDropdown.Item onClick = { ()=> handleSetLanguage('eng') }>Eng</NavDropdown.Item>
				        <NavDropdown.Item onClick = { ()=> handleSetLanguage('tra') }>中(繁)</NavDropdown.Item>
                <NavDropdown.Item onClick = { ()=> handleSetLanguage('sim') }>中(简)</NavDropdown.Item>
				     </NavDropdown>
				    :getLanguage()=='tra'
              ?<NavDropdown  drop = "left" title="中(繁)" id="basic-nav-dropdown"  style = {{marginTop:"-9px"}}>
				        <NavDropdown.Item onClick = { ()=> handleSetLanguage('eng') }>Eng</NavDropdown.Item>
				        <NavDropdown.Item onClick = { ()=> handleSetLanguage('tra') }>中(繁)</NavDropdown.Item>
                <NavDropdown.Item onClick = { ()=> handleSetLanguage('sim') }>中(简)</NavDropdown.Item>
				      </NavDropdown>
              :<NavDropdown  drop = "left" title="中(简)" id="basic-nav-dropdown"  style = {{marginTop:"-9px"}}>
                <NavDropdown.Item onClick = { ()=> handleSetLanguage('eng') }>Eng</NavDropdown.Item>
                <NavDropdown.Item onClick = { ()=> handleSetLanguage('tra') }>中(繁)</NavDropdown.Item>
                <NavDropdown.Item onClick = { ()=> handleSetLanguage('sim') }>中(简)</NavDropdown.Item>
              </NavDropdown>
				   }
			    </Nav>
			  </Navbar.Collapse>

			</Navbar>
		);
}


export default translate(Header);
