import React, {useState, useEffect} from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown'
import { Link, useHistory } from "react-router-dom"
import { CloudinaryContext, Image } from "cloudinary-react";
import './ManageNews.css'
import { openUploadWidget } from "../CloudinaryService";
import Confirmation from '../components/Confirmation.js'
import { format } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
const ManageNews = () => {
	const moment = require('moment')
  const [news, setNews] = useState([{
  	coverId:'',
  	category:'',
  	eng:{
  		header:'',
  		content:'',
  		photoId:'',
  		video:''
  	},
  	tra:{
  		header:'',
  		content:'',
  		photoId:'',
  		video:''
  	},
	sim:{
  		header:'',
  		content:'',
  		photoId:'',
  		video:''
  	}
  }])
	const [year, setYear] = useState(new Date().getFullYear())

	function range(start, end) {
	  return Array(end - start + 1).fill().map((_, idx) => start + idx).sort((a,b)=>b-a)
	}
	const yearsArray = range(1998,new Date().getFullYear())
	const callApi = async (year) => {
	    const response = await fetch('/api/news?year='+year.toString(), {
        method: "GET"
      })
	    const body = await response.json();
	    if (response.status !== 200) throw Error(body.message);

	    return body;
	  };

	useEffect(() => {
	    callApi(year)
	      .then(res => 
	        {
	          const newsBody = res.news;
	          console.log(newsBody)
	          setNews(newsBody)
	        })
	      .catch(err => console.log(err));
	}, []);

	const deletePopup = (id, title) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want to delete the post: ' + title,
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteClicked(id)
        },
        {
          label: 'No',
          onClick: () => alert('You have not deleted the post: ' + title )
        }
      ]
    });
  };
	const deleteClicked= async (id) => {
       const response = await fetch('/api/deleteNews', {
	      method: 'delete',
	      headers: {
	        'Content-Type': 'application/json',
	      },
	      body: JSON.stringify({news_id:id}),
	    });
       const body = await response.text().then(response => {window.location.reload(true)})
    };

    const starClicked = async (id,starred) => {
    	 const response = await fetch('/api/changeStar', {
	      method: 'POST',
	      headers: {
	        'Content-Type': 'application/json',
	      },
	      body: JSON.stringify({news_id:id,starred:starred}),
	    });
	    const body = await response.text().then(response => {window.location.reload(true)})

    }

	const listNews = news.map((article) =>
		  <div key = {article._id || ""} class = 'mn-row'>
			<div class="mn-image-box">
	       		{article.eng.video==""
			  		?<Image className = 'archive-news-image' publicId={article.coverId+'.jpg'}></Image>
			  		:<iframe width = '184px' height = '166px' src={'https://www.youtube.com/embed/'+article.eng.video}></iframe>
			  	}
	    	 </div>
			<p> {article.eng.header} </p>
			<div class ='mn-content'> {(article.eng.content || "").substring(0,300)}... </div>
			<p> {moment(article.date).format('MMMM Do YYYY')} </p>
			<Link
			  to={{
			    pathname: "/cms/EditNews",
			    state: { _id: article._id}
			  }}
			  class = 'edit-link'
			>Edit </Link>
			<a class = 'delete-link' onClick= {()=>deletePopup(article._id,article.eng.header)}> Delete </a>
			{article.starred?
				<p class = 'star-link' onClick= {()=> starClicked(article._id,false)}> ★</p>
				:<p class = 'star-white-link' onClick= {()=> starClicked(article._id,true)}> ☆</p>
			}
		</div>
	);

	const yearDropdown = yearsArray.map((year) =>
		<Dropdown.Item key= {year ||""} onClick={()=>changeYear({year})}>{year}</Dropdown.Item>
	);
	const changeYear = (year) => {
		setYear(year.year.toString())
		callApi(year.year.toString()).then(res => 
	        {
	          const newsBody = res.news;
	          setNews(newsBody)
	        })
	      .catch(err => console.log(err));
	}

	return (
		<CloudinaryContext cloudName="dn5v5udu6">
			<div class = 'mn-container'> 
				<Link to="/cms" class = 'home-link'> Back Home </Link> <br />
			    <Link to="/cms/addnews" class = 'home-link'>Add Latest News </Link><br />
	        	<h2> Manage News & Archive </h2>
	        	<div class = 'mn-year-container'>
	        		<div>Published News </div>
	        		<DropdownButton id="dropdown-basic-button" title={year.toString()}>
	        		  {yearDropdown}
					</DropdownButton>
				</div>
				<p>Click the star icon to feature articles in the latest news. Only 4 articles can be chosen.</p>
				<div class = 'mn-subcontainer'>
					<div class = 'mn-row'>
						<b> Cover picture </b>
						<b> Header </b>
						<b> Content </b>
						<b> Published Date </b>
					</div>
					{listNews}
				</div>
			</div>
    	</CloudinaryContext>
	)
}
export default ManageNews