import React, {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import './Login.css'
const env = process.env.REACT_APP_ENV
const Login  = props => {
    useEffect(() => {
      const listener = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          console.log("Enter key was pressed. Run your function.");
          props.handleLogin()
        }
      };
      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
      };
    });

    const changeUsername = (e) => {
      props.handleUsername(e.target.value)
    }

    const changePassword = (e) => {
      props.handlePassword(e.target.value)
    }
    return (
      <div>
      {!props.login?
      <div className = 'landing-container'>
        <div className = 'landing-subcontainer'>
          <div>
          <h2>Login</h2>
          </div>
        </div>
        <p className = 'landing-label'>Username</p>
        <input className = 'landing-input' value = {props.username} onChange = {changeUsername}></input>
        <p className = 'landing-label'>Password</p>
        <input className = 'landing-input' type="password" onChange = {changePassword}></input>

        <button className = 'landing-button pointer' onClick = {props.handleLogin} >Log In</button>
      </div>
      :
      <Redirect to='/cms' />
      }
      </div>
    )
  };

export default Login;