import React, {useState, useEffect} from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown'
import { Link, useHistory } from "react-router-dom"
import { CloudinaryContext, Image } from "cloudinary-react";
import './ManageCharityItems.css'
import { openUploadWidget } from "../CloudinaryService";
import Confirmation from '../components/Confirmation.js'
import { format } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from 'react-bootstrap/Button';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
const ManageCharityItems = () => {
	const moment = require('moment')
  const [showButton, setShowButton] =useState(false)
  const [show, setShow] = useState(false)
  const [items, setItems] = useState([{
  	coverId:['','','',''],
  	price:'',
  	order:'',
  	eng:{
  		name:'',
  		tag:'',
  		desc:'',
  	},
  	tra:{
  		name:'',
  		tag:'',
  		desc:''
  	},
  	sim:{
  		name:'',
  		tag:'',
  		desc:''
  	}

  }])

	const callApi = async () => {
	    const response = await fetch('/api/charityItems')
	    const body = await response.json();
	    if (response.status !== 200) throw Error(body.message);

	    return body;
	  };

	useEffect(() => {
	    callApi()
	      .then(res => 
	        {
	          const itemsBody = res.items;
	          console.log(itemsBody)
	          setItems(itemsBody)
	        })
	      .catch(err => console.log(err));
	}, []);
	const shouldCancelStart = (e) => {
        // Cancel sorting if the event target is an anchor tag (`a`)
        if (e.target.tagName.toLowerCase() === 'a') {
            return true; // Return true to cancel sorting
        }
    }
	const deletePopup = (id, title) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want to delete the post: ' + title,
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteClicked(id)
        },
        {
          label: 'No',
          onClick: () => alert('You have not deleted the post: ' + title )
        }
      ]
    });
  };
	const deleteClicked= async (id) => {
       const response = await fetch('/api/deleteCharityItem', {
	      method: 'delete',
	      headers: {
	        'Content-Type': 'application/json',
	      },
	      body: JSON.stringify({item_id:id}),
	    });
       const body = await response.text().then(response => {window.location.reload(true)})
    };

    const handleUpdateOrder = async () => {
    	    const response = await fetch('/api/updateCharityOrder', {
		      method: 'POST',
		      headers: {
		        'Content-Type': 'application/json',
		      },
		      body: JSON.stringify({items:items}),
		    })
		    const body = await response.json()
		    setShow(true);
		    const timerId = setInterval(() => {
		      setShow(false)
		    }, 1000);

    }
    //plan is to create an array of _id based on the new order
    //back end then set each one by update
    //create button to update order
    //call things sort by order
	const onSortEnd = ({ oldIndex, newIndex }) => {
		setItems(arrayMove(items, oldIndex, newIndex))
		setShowButton(true)
	};
    const SortableItemsContainer = sortableContainer(({ children }) => <div>{children}</div>);
    const renderTooltip = (props) => (
	  <Tooltip id="button-tooltip" {...props}>
	    Drag the item to change its order
	  </Tooltip>
	);
    const CharityItem = (item) => {
    	return(
    	<div>	  
    	    	  <OverlayTrigger
		    placement="top"
		    delay={{ show: 250, hide: 400 }}
		    overlay={renderTooltip}
		  >
    	<div key = {item.item._id || ""} class = 'mc-row pointer'>

			<div class="mc-image-box">
	       		<Image publicId={item.item.coverId[0]}></Image>
	    	 </div>
	    	 <div class = 'mc-name'>
					<p> {item.item.eng.name} </p>
					<div class = 'mc-tag'>
					 {item.item.eng.tag}
					</div>
			</div>
			<p> {item.item.eng.desc.substring(0,100)}... </p>
			<p> {item.item.price} </p>
			<div class = 'mc-links-container'>
				<Link
				  to={{
				    pathname: "/cms/EditCharityItem",
				    state: { _id: item.item._id}
				  }}
				  class = 'edit-link'
				>Edit </Link>
				<a class = 'delete-link' onClick= {()=>deletePopup(item.item._id,item.item.eng.name)}> Delete </a>
			</div>
		</div>
		</OverlayTrigger>
		</div>
		)
	}
    const SortableItem = sortableElement(({ item }) => <CharityItem key={item._id} item={item} />);
    const SortableListItems = items.map((item, i) =>{
    	return(
		    <SortableItem
		    // don't forget to pass index prop with item index
		      index={i}
		      key={item._id}
		      item ={item}
		    />
		)
	})


    const listItems = items.map((item,i)=>{
    	return(
    		<CharityItem key={item._id} item={item} />
    	)
    })
	return (
		<CloudinaryContext cloudName="dn5v5udu6">
		    	<Confirmation show = {show} sentence = 'Order Updated'/>
			<div class = 'mc-container'> 
				<Link to="/cms" class = 'home-link'> Back Home </Link> <br />
				<div class = 'mc-title'>
		        	<h2> Manage Charity Items </h2>
		        	<div> </div>
		        	{showButton?
		        		<Button onClick = {handleUpdateOrder}> Update Order </Button> 
		        		:<Button onClick = {handleUpdateOrder} disabled> Update Order </Button>
		        	} 
		        	<Link to='/cms/addcharityitem'> <div class = 'mc-button'><Button> + Add A New Item </Button> </div></Link>
	        	</div>
				<div class = 'mc-subcontainer'>
					<div class = 'mc-row'>
						<b> Cover picture </b>
						<b> Item name </b>
						<b> Item's Description </b>
						<b> Price </b>
					</div>
					<SortableItemsContainer axis="y" onSortEnd={onSortEnd} shouldCancelStart={shouldCancelStart}>
				    {SortableListItems}
				    </SortableItemsContainer>
				</div>
			</div>
    	</CloudinaryContext>
	)
}
export default ManageCharityItems