import React, {useState, useEffect} from 'react';
import { Link, useHistory } from "react-router-dom"
import { CloudinaryContext, Image } from "cloudinary-react";
import './OrgStructure.css'
import { openUploadWidget } from "../CloudinaryService";
import Confirmation from '../components/Confirmation.js'
const OrgStructure = () => {
  let history = useHistory();
    const [show, setShow] = useState(false)
	const [engPatron, setEngPatron] = useState([ 
		    {
		      order:0, 	
		      patronName: '',
		      patronTitle: ''
		    },
		]
	);

	const [engStaff, setEngStaff] = useState([
	    {
	      order:0, 	
	      staffName: '',
	      staffTitle: ''
	    },
	]);
	const [engNames, setEngNames] = useState({
			founder:'',
			trustee:'',
			members:'',
			advisors:'',
			membersSplit:[],
			advisorsSplit:[]
	})
	const [traPatron, setTraPatron] = useState([ 
		    {
		      order:0, 	
		      patronName: '',
		      patronTitle: ''
		    },
		]
	);

	const [traStaff, setTraStaff] = useState([
	    {
	      order:0, 	
	      staffName: '',
	      staffTitle: ''
	    },
	]);
	const [traNames, setTraNames] = useState({
			founder:'',
			trustee:'',
			members:'',
			advisors:'',
			membersSplit:[],
			advisorsSplit:[]
	})

	const [simPatron, setSimPatron] = useState([ 
		    {
		      order:0, 	
		      patronName: '',
		      patronTitle: ''
		    },
		]
	);

	const [simStaff, setSimStaff] = useState([
	    {
	      order:0, 	
	      staffName: '',
	      staffTitle: ''
	    },
	]);
	const [simNames, setSimNames] = useState({
			founder:'',
			trustee:'',
			members:'',
			advisors:'',
			membersSplit:[],
			advisorsSplit:[]
	})

  	useEffect(() => {

	    const callApi = async () => {

	      const response = await fetch('/api/orgStructure')
	      const body = await response.json();
	      if (response.status !== 200) throw Error(body.message);

	      return body;
	    };
	    callApi()
	      .then(res => 
	        {
	          const orgBody = res.orgStructure[0];
	          console.log(orgBody)
	          setEngNames({ 
		  		founder:orgBody.combined.eng.founder,
				trustee:orgBody.combined.eng.trustee,
				members:orgBody.combined.eng.members,
				advisors:orgBody.combined.eng.advisors,
				membersSplit:orgBody.combined.eng.membersSplit,
				advisorsSplit:orgBody.combined.eng.advisorsSplit
		  	  })
		  	  setEngStaff(orgBody.combined.eng.engStaff)
		  	  setEngPatron(orgBody.combined.eng.engPatron)
	          setTraNames({ 
		  		founder:orgBody.combined.tra.founder,
				trustee:orgBody.combined.tra.trustee,
				members:orgBody.combined.tra.members,
				advisors:orgBody.combined.tra.advisors,
				membersSplit:orgBody.combined.tra.membersSplit,
				advisorsSplit:orgBody.combined.tra.advisorsSplit
		  	  })
		  	  setTraStaff(orgBody.combined.tra.traStaff)
		  	  setTraPatron(orgBody.combined.tra.traPatron)
	          setSimNames({ 
		  		founder:orgBody.combined.sim.founder,
				trustee:orgBody.combined.sim.trustee,
				members:orgBody.combined.sim.members,
				advisors:orgBody.combined.sim.advisors,
				membersSplit:orgBody.combined.sim.membersSplit,
				advisorsSplit:orgBody.combined.sim.advisorsSplit
		  	  })
		  	  setSimStaff(orgBody.combined.sim.simStaff)
		  	  setSimPatron(orgBody.combined.sim.simPatron)

	        })
	      .catch(err => console.log(err));
	  }, []);

  function handleEngPatronChange(evt) {
	  const value = evt.target.value;
	  let newPatron = [...engPatron];
	  newPatron[evt.target.id][evt.target.name] = value;
	  setEngPatron(newPatron)
  }

  function handleEngStaffChange(evt) {
	  const value = evt.target.value;
	  let newStaff = [...engStaff];
	  newStaff[evt.target.id][evt.target.name] = value;
	  setEngStaff(newStaff)
  }

  function handleEngChange(evt){
  	const value = evt.target.value;
  	if (evt.target.name == 'members' || evt.target.name == 'advisors'){
  		let namesSplit = evt.target.value.split(/\r?\n/);
  	    setEngNames({ 
	  		...engNames,
	  		[evt.target.name]:value,
	  		[evt.target.id]:namesSplit
  		})
  	}
  	else {
  		setEngNames({ 
		  		...engNames,
		  		[evt.target.name]:value
  		})
  	}
  }

  const newEngPatronButton = () => {
  	setEngPatron([...engPatron,{order:engPatron.length,patronName:'',patronTitle:''}])
  }
   const newEngStaffButton = () => {
     setEngStaff([...engStaff,{order:engStaff.length,staffName:'',staffTitle:''}])
  }

    const showEngPatrons = engPatron.map((person,idx) => 
	  	<div>
			<p><b> Patron {idx+1}</b></p>
			<p> Name </p>
			<textarea id={idx} name='patronName' class = 'org-name-ta' placeholder='' value={engPatron[idx].patronName} onChange={handleEngPatronChange} />
			<p> Title</p>
			<textarea id={idx} name='patronTitle' class = 'org-title-ta' placeholder='' value={engPatron[idx].patronTitle} onChange={handleEngPatronChange} />
	    </div>
	)

  const showEngStaff = engStaff.map((person,idx) => 
	  	<div>
			<p><b> Staff {idx+1}</b></p>
			<p> Name </p>
			<textarea id={idx} name='staffName' class = 'org-name-ta' placeholder='' value={engStaff[idx].staffName} onChange={handleEngStaffChange} />
			<p> Title</p>
			<textarea id={idx} name='staffTitle' class = 'org-title-ta' placeholder='' value={engStaff[idx].staffTitle} onChange={handleEngStaffChange} />
	    </div>
	)


  function handleTraPatronChange(evt) {
	  const value = evt.target.value;
	  let newPatron = [...traPatron];
	  newPatron[evt.target.id][evt.target.name] = value;
	  setTraPatron(newPatron)
  }

  function handleTraStaffChange(evt) {
	  const value = evt.target.value;
	  let newStaff = [...traStaff];
	  newStaff[evt.target.id][evt.target.name] = value;
	  setTraStaff(newStaff)
  }

  function handleTraChange(evt){
  	const value = evt.target.value;
  	if (evt.target.name == 'members' || evt.target.name == 'advisors'){
  		let namesSplit = evt.target.value.split(/\r?\n/);
  	    setTraNames({ 
	  		...traNames,
	  		[evt.target.name]:value,
	  		[evt.target.id]:namesSplit
  		})
  	}
  	else {
  		setTraNames({ 
		  		...traNames,
		  		[evt.target.name]:value
  		})
  	}
  }

  const newTraPatronButton = () => {
  	setTraPatron([...traPatron,{order:traPatron.length,patronName:'',patronTitle:''}])
  }
   const newTraStaffButton = () => {
     setTraStaff([...traStaff,{order:traStaff.length,staffName:'',staffTitle:''}])
  }

    const showTraPatrons = traPatron.map((person,idx) => 
	  	<div>
			<p><b> 贊助人 {idx+1}</b></p>
			<p> Name </p>
			<textarea id={idx} name='patronName' class = 'org-name-ta' placeholder='' value={traPatron[idx].patronName} onChange={handleTraPatronChange} />
			<p> Title</p>
			<textarea id={idx} name='patronTitle' class = 'org-title-ta' placeholder='' value={traPatron[idx].patronTitle} onChange={handleTraPatronChange} />
	    </div>
	)

  const showTraStaff = traStaff.map((person,idx) => 
	  	<div>
			<p><b> Staff {idx+1}</b></p>
			<p> Name </p>
			<textarea id={idx} name='staffName' class = 'org-name-ta' placeholder='' value={traStaff[idx].staffName} onChange={handleTraStaffChange} />
			<p> Title</p>
			<textarea id={idx} name='staffTitle' class = 'org-title-ta' placeholder='' value={traStaff[idx].staffTitle} onChange={handleTraStaffChange} />
	    </div>
	)

  function handleSimPatronChange(evt) {
	  const value = evt.target.value;
	  let newPatron = [...simPatron];
	  newPatron[evt.target.id][evt.target.name] = value;
	  setSimPatron(newPatron)
  }

  function handleSimStaffChange(evt) {
	  const value = evt.target.value;
	  let newStaff = [...simStaff];
	  newStaff[evt.target.id][evt.target.name] = value;
	  setSimStaff(newStaff)
  }

  function handleSimChange(evt){
  	const value = evt.target.value;
  	if (evt.target.name == 'members' || evt.target.name == 'advisors'){
  		let namesSplit = evt.target.value.split(/\r?\n/);
  	    setSimNames({ 
	  		...simNames,
	  		[evt.target.name]:value,
	  		[evt.target.id]:namesSplit
  		})
  	}
  	else {
  		setSimNames({ 
		  		...simNames,
		  		[evt.target.name]:value
  		})
  	}
  }

  const newSimPatronButton = () => {
  	setSimPatron([...simPatron,{order:simPatron.length,patronName:'',patronTitle:''}])
  }
   const newSimStaffButton = () => {
     setSimStaff([...simStaff,{order:simStaff.length,staffName:'',staffTitle:''}])
  }

    const showSimPatrons = simPatron.map((person,idx) => 
	  	<div>
			<p><b> 赞助人  {idx+1}</b></p>
			<p> Name </p>
			<textarea id={idx} name='patronName' class = 'org-name-ta' placeholder='' value={simPatron[idx].patronName} onChange={handleSimPatronChange} />
			<p> Title</p>
			<textarea id={idx} name='patronTitle' class = 'org-title-ta' placeholder='' value={simPatron[idx].patronTitle} onChange={handleSimPatronChange} />
	    </div>
	)

  const showSimStaff = simStaff.map((person,idx) => 
	  	<div>
			<p><b> Staff {idx+1}</b></p>
			<p> Name </p>
			<textarea id={idx} name='staffName' class = 'org-name-ta' placeholder='' value={simStaff[idx].staffName} onChange={handleSimStaffChange} />
			<p> Title</p>
			<textarea id={idx} name='staffTitle' class = 'org-title-ta' placeholder='' value={simStaff[idx].staffTitle} onChange={handleSimStaffChange} />
	    </div>
	)

  const handleSubmit = async () => {

  	let engPatronJSON = {
  		engPatron:engPatron
  	}
  	let engStaffJSON = {
  		engStaff:engStaff
  	}
  	let engCombined = Object.assign(Object.assign(engNames,engPatronJSON),engStaffJSON)

    let traPatronJSON = {
  		traPatron:traPatron
  	}
  	let traStaffJSON = {
  		traStaff:traStaff
  	}
  	let traCombined = Object.assign(Object.assign(traNames,traPatronJSON),traStaffJSON)
  	let simPatronJSON = {
  		simPatron:simPatron
  	}
  	let simStaffJSON = {
  		simStaff:simStaff
  	}
  	let simCombined = Object.assign(Object.assign(simNames,simPatronJSON),simStaffJSON)
  	let combined = {
  		eng:engCombined,
  		tra:traCombined,
  		sim:simCombined
  	}
  	console.log(combined)
    const response = await fetch('/api/addorgstructure', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({combined}),
    })
    const body = await response.json()
    setShow(true);
    const timerId = setInterval(() => {
      setShow(false)
    }, 2000);


  };



  return (
    <CloudinaryContext cloudName="dn5v5udu6">
    	<Confirmation show = {show} sentence = 'Note Uploaded'/>
    	<div class = 'org-container'>
        	<Link to="/cms" class = 'home-link'> Back Home </Link>
	        	<h2> Edit Organization Structure </h2>
	        <div class = 'org-subcontainer'>
	        	<div class = 'org-box'>
	        		<div class = 'org-language-box'>
	        			<b>English version</b>
	        			<div class = 'org-row'>
	        				<div>
	        					<h6>Patron</h6>
	        					{showEngPatrons}
	        				</div>
	        				<div>
	        					<h6>Founder & Chairman</h6>
	        					<p style = {{'margin-top':'47px'}}>Name</p>
								<textarea name='founder' class = 'org-name-ta' placeholder='' value={engNames.founder} onChange={handleEngChange} />
	        				</div>
	        				<div></div>
	        				<button onClick = {newEngPatronButton}>New Patron</button>
	        			</div>
	        			<div class = 'org-row'>
	        				<div>
	        					<h6>Board of Directors</h6>
	        					<p>Names</p>
								<textarea id='membersSplit' name='members' class = 'org-names-ta' placeholder='' value={engNames.members} onChange={handleEngChange} />
	        				</div>
	        				<div>
	        					<h6>Medical Advisors</h6>
	        					<p>Names</p>
								<textarea id='advisorsSplit' name='advisors' class = 'org-names-ta' placeholder='' value={engNames.advisors} onChange={handleEngChange} />
	        				</div>
	        			</div>
	        			<div>
	        				<h6>FSMA</h6>
	        				<div  class = 'org-staff-row'>
	        					{showEngStaff}
	        				</div>
	        				<button onClick = {newEngStaffButton}>+ Add New Staff</button>
	        			</div>
	        		</div>
	        	</div>
	        </div>
	    </div>
	    <div class = 'org-container'>
	        <div class = 'org-subcontainer'>
	        	<div class = 'org-box'>
	        		<div class = 'org-language-box'>
	        			<b>Traditional Chinese Version</b>
	        			<div class = 'org-row'>
	        				<div>
	        					<h6>贊助人</h6>
	        					{showTraPatrons}
	        				</div>
	        				<div>
	        					<h6>創辦人及主席</h6>
	        					<p style = {{'margin-top':'47px'}}>Name</p>
								<textarea name='founder' class = 'org-name-ta' placeholder='' value={traNames.founder} onChange={handleTraChange} />
	        				</div>
	        				<div></div>
	        				<button onClick = {newTraPatronButton}>New Patron</button>
	        			</div>
	        			<div class = 'org-row'>
	        				<div>
	        					<h6>董事會</h6>
	        					<p>Names</p>
								<textarea id='membersSplit' name='members' class = 'org-names-ta' placeholder='' value={traNames.members} onChange={handleTraChange} />
	        				</div>
	        				<div>
	        					<h6>醫學顧問</h6>
	        					<p>Names</p>
								<textarea id='advisorsSplit' name='advisors' class = 'org-names-ta' placeholder='' value={traNames.advisors} onChange={handleTraChange} />
	        				</div>
	        			</div>
	        			<div>
	        				<h6>本會職員</h6>
	        				<div  class = 'org-staff-row'>
	        					{showTraStaff}
	        				</div>
	        				<button onClick = {newTraStaffButton}>+ Add New Staff</button>
	        			</div>
	        		</div>
	        	</div>
	    	 </div>
	    </div>
	    <div class = 'org-container'>
	        <div class = 'org-subcontainer'>
	        	<div class = 'org-box'>
	        		<div class = 'org-language-box'>
	        			<b>Simplified Chinese Version</b>
	        			<div class = 'org-row'>
	        				<div>
	        					<h6>赞助人 </h6>
	        					{showSimPatrons}
	        				</div>
	        				<div>
	        					<h6>创办人及主席</h6>
	        					<p style = {{'margin-top':'47px'}}>Name</p>
								<textarea name='founder' class = 'org-name-ta' placeholder='' value={simNames.founder} onChange={handleSimChange} />
	        				</div>
	        				<div></div>
	        				<button onClick = {newSimPatronButton}>New Patron</button>
	        			</div>
	        			<div class = 'org-row'>
	        				<div>
	        					<h6>董事会</h6>
	        					<p>Names</p>
								<textarea id='membersSplit' name='members' class = 'org-names-ta' placeholder='' value={simNames.members} onChange={handleSimChange} />
	        				</div>
	        				<div>
	        					<h6>医学顾问</h6>
	        					<p>Names</p>
								<textarea id='advisorsSplit' name='advisors' class = 'org-names-ta' placeholder='' value={simNames.advisors} onChange={handleSimChange} />
	        				</div>
	        			</div>
	        			<div>
	        				<h6>本会职员</h6>
	        				<div  class = 'org-staff-row'>
	        					{showSimStaff}
	        				</div>
	        				<button onClick = {newSimStaffButton}>+ Add New Staff</button>
	        			</div>
	        		</div>
	        	</div>
	    	 </div>

	    	<button onClick = {handleSubmit}>Submit</button>
		</div>

	
    </CloudinaryContext>
  );
}
export default OrgStructure