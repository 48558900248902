import React, {useState, useEffect, useMemo} from 'react';
import { CloudinaryContext, Image } from "cloudinary-react";
import { Link } from "react-router-dom"
import './Patient.css'
import { openUploadWidget } from "../CloudinaryService";
import Confirmation from '../components/Confirmation.js'
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
const Patient = () => {
  const [show, setShow] = useState(false)
  const [newForm, setNewForm] = useState(false)
  const [formValue, setFormValue] = useState ('')
  const [forms, setForms] = useState([{lang:[]}])
  const [lang,setLang] = useState([])
  const [namesArr, setNamesArr] = useState([])
  const submitForm = async (pdfId,original_filename) => {
    const response = await fetch('/api/addForm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({pdfId:pdfId,formValue:formValue,eng:{formValue:formValue},tra:{formValue:formValue},sim:{formValue:formValue},originalFilename:original_filename, lang:lang }),
    })
    const body = await response.json()
    setShow(true);
    const timerId = setInterval(() => {
      setShow(false)
      callApi()
	      .then(res => 
	        {
	          const formsBody = res.forms;
	          setForms(formsBody)
	        })
	      .catch(err => console.log(err));
    }, 2000);

  };

  const callApi = async () => {
	    const response = await fetch('/api/getForms', {
        method: "GET"
      })
	    const body = await response.json();
	    if (response.status !== 200) throw Error(body.message);

	    return body;
	  };

	useEffect(() => {
	    callApi()
	      .then(res => 
	        {
	        console.log(res)
	          const formsBody = res.forms;
	          console.log(formsBody)
	          setForms(formsBody)
	        })
	      .catch(err => console.log(err));
	}, []);

   const showChangeName = (idx) => {
   		setNamesArr([...namesArr, idx])
   		console.log(namesArr)
   }


   function FormsList(props) {
   	const [showEdit,setShowEdit] = useState(false)
   	const [form,setForm] = useState(props.form)
    const handleEditForm = async () => {
    	console.log('handleEditForm')
    	const response = await fetch('/api/editFormName', {
	      method: 'POST',
	      headers: {
	        'Content-Type': 'application/json',
	      },
	      body: JSON.stringify({form_id:form._id,formValue:form.formValue}),
	    })
	    const body = await response.json()
	    setShowEdit(false)
    }

    useEffect(() => {
    	console.log(form)
    },[form])

    const replaceUpload = tag => {
	  const uploadOptions = {
	    cloudName: "dn5v5udu6",
	    tags: ['pdf'],
	    uploadPreset: "upload"
	  };

	  const replaceForm = async (pdfId,original_filename) => {
	    const response = await fetch('/api/replaceForm', {
	      method: 'POST',
	      headers: {
	        'Content-Type': 'application/json',
	      },
	      body: JSON.stringify({form_id:form._id,pdfId:pdfId,originalFilename:original_filename }),
	    })
	    const body = await response.json()

	  };

	  openUploadWidget(uploadOptions, (error, pdf) => {
	    if (!error) {
	      if(pdf.event === 'success'){

	      	replaceForm(pdf.info.public_id,pdf.info.original_filename)
	      	setForm({
	      		...form,
	      		pdfId:pdf.info.public_id,
	      		originalFilename:pdf.info.original_filename
	      	},console.log(form.originalFilename))
	      }
	    } else {
	      console.log(error);
	    }
	  })
	}
    const handleShowForm = () => {
    	setShowEdit(true)
    }
    const handleFormValueChange = (event) => {
    	setForm({
    		...form,
    		formValue:event.target.value
    		})
    }

   	return(
   		<div class = 'form-row'>
	  		<div>
	  		<b>{form.formValue}</b><a class = 'edit-font' onClick = {handleShowForm}> Edit name displayed on the site </a>
	  		</div> 
	  		{showEdit &&
	  			<div class = 'edit-form'>
	  				Edit Name of Form: <input class = 'patient-ta' value={form.formValue} onChange={handleFormValueChange} />
	  				<button class = 'edit-btn' onClick = {()=>handleEditForm()}> Edit Form </button>
	  			</div>
	  		}	
	  		<div class= 'inline'>
					<a href = {'https://res.cloudinary.com/dn5v5udu6/image/upload/fl_attachment/'+form.pdfId+'.pdf'}>
					Current form: {form.originalFilename}.pdf</a> 
					<i> @ </i> 
					{form.lang.map(lang => (
						<div class = 'tag' key = {lang + form._id}>
						{(() => {
					        if (lang=='eng') {
					          return (
					            <div>Eng Ver.</div>
					          )
					        } else if (lang=='tra') {
					          return (
					            <div>TC Ver.</div>
					          )
					        } else {
					          return (
					            <div>SC Ver.</div>
					          )
					        }
					      })()}
						</div>))}
					<i> website</i>
			</div>
			<button class = 'replace-btn' onClick = {replaceUpload}>Replace Form</button>
		</div>
   		)
   }
  

    const handleSelect=(e)=>{
    	console.log(e)
    	setLang(e)

  }

  const handleFormValue = (event) =>{
  	setFormValue(event.target.value);
  }
  const handleAddForm = () => {
  	setNewForm(true);
  }

  const beginUpload = tag => {
	  const uploadOptions = {
	    cloudName: "dn5v5udu6",
	    tags: [tag, 'pdf'],
	    uploadPreset: "upload"
	  };


	  openUploadWidget(uploadOptions, (error, pdf) => {
	    if (!error) {
	      if(pdf.event === 'success'){
	      	console.log(pdf.info)
	      	submitForm(pdf.info.public_id,pdf.info.original_filename)

	      }
	    } else {
	      console.log(error);
	    }
	  })
	}

  return (
    <CloudinaryContext cloudName="dn5v5udu6">
    	<Confirmation show = {show} sentence = 'PDF Uploaded'/>
    	<div class = 'patient-container'>
        	<Link to="/cms" class = 'home-link'> Back Home </Link>
	        	<h2> Change Patient Forms </h2>
	        <div class = 'patient-subcontainer'>
	        	<div class = 'patient-box'>
	        		<div class = 'language-box'>
	        			{forms.map((form, idx)=>
	        				<div key={idx}>
	        					<FormsList form={form} />
	        				</div>)}
	        		</div>
	
	        	</div>
	        </div>
	        {newForm? 
	        	<div class = 'new-form-box'>
	        		<b>Add New Form</b> <br />
	        		Upload it to: 
						<ToggleButtonGroup className = 'patient-checkbox' type="checkbox" title="Choose A Category" onChange={handleSelect}>
					      <ToggleButton size="sm" variant="secondary" value="eng">English ver.</ToggleButton>
					      <ToggleButton size="sm" variant="secondary" value="tra">Traditional Chinese ver.</ToggleButton>
					      <ToggleButton size="sm" variant="secondary" value="sim">Simplified Chinese ver.</ToggleButton>
					    </ToggleButtonGroup> <br /> <br />
	        		Name of New Form: <textarea class = 'patient-ta' value={formValue} onChange={handleFormValue} />
	        		<br/>
	        		<button onClick={() => beginUpload('pdf')}>Upload New Form</button>

	        	</div>
	        	:<button onClick = {handleAddForm}>+ Add New Form</button>}
	    </div>
    </CloudinaryContext>
  );
}
export default Patient