import React, { useState, useEffect } from 'react'
import './SMA.css'
import Donation from './components/Donation.js'
import { CloudinaryContext, Image } from "cloudinary-react";
import Header from './Header.js'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import Footer from './Footer.js'
import Contact from './components/Contact.js'
import { translate,getLanguage } from 'react-switch-lang';
const SMA = (props) => {
	 const { t } = props;
	 let ReactSafeHtml = require('react-safe-html');
	 const [current,setCurrent] = useState(0)
	 const [further,setFurther] = useState(false)
	 const [items, setItems] = useState([{
	 	eng:{
	 		title:'',
	 		references:[],
	 		items:[
	 		{
	 			subTitle:'',
	 			content:''
	 		}
	 		]
	 	},
	 		 	tra:{
	 		title:'',
	 		references:[],
	 		items:[
	 		{
	 			subTitle:'',
	 			content:''
	 		}
	 		]
	 	},
	 		 	sim:{
	 		title:'',
	 		references:[],
	 		items:[
	 		{
	 			subTitle:'',
	 			content:''
	 		}
	 		]
	 	},
	 	coverId:''
	 }])
	 useEffect(() => {
	    const callSMA = async () => {
	    	const response = await fetch ('/api/sma');
	    	const body = await response.json();
		    if (response.status !== 200) throw Error(body.message);

		    return body;
	    }

	    callSMA()
	    	.then (res =>
	    	{

	    		setItems(res.sma)
	    	})
	    	.catch(err => console.log(err));
	    
	  }, []); 

    const changeCurrent = (item,index) => {
    	if(item.eng.title=='Further Information' || item.eng.title=='SMA Treatment')
    	{
    		setFurther(true)
    	}
    	else{
    		setFurther(false)
    	}
    	setCurrent(index)
    }
	 
	const showLinks = items.map((item,index) => 
		 <div>
		 	<div class = 'pointer' onClick = {()=>changeCurrent(item,index)}>
		 	<Image publicId={item.coverId} className = 'item-image'></Image>
		 		{console.log(item[getLanguage()].title)}
				{item[getLanguage()].title || ""}
		 		
			</div>
		 </div>
		)

 	function SMAItems(props) {
 		const [cat, setCat] = useState(props.cat)
 		function SMAItem(props) {
 			const [showItem, setShowItem] = useState(false)
 			const [item, setItem] = useState(props.item)
 			return(
 				<div class = 'sma-items'>
	 			 	<div class = 'sma-title pointer' onClick = {()=> setShowItem(!showItem)}>
	 					{item.subTitle} 
	 					{showItem
	 						? <img src = './up_arrow.png' />
	 						: <img src = './down_arrow.png' />
	 					}
	 				</div>		
	 				<p>{showItem&&
	 					<ReactSafeHtml html={item.content} />}</p>
 				</div>

 			)
 		}
 		const displayItems = cat[getLanguage()].items.map(item => {
 			return(
		 			<div >
		 				<SMAItem item = {item} />
		 			</div>
		 		)
		 	})
 		return (
 			<div>
 				{cat.eng.title == 'Further Information'
 					?<div class = 'further-row'>
 						<div>
 							<div> 
 								<img src="http://res.cloudinary.com/dn5v5udu6/image/upload/Standards_of_Care_for_SMA_Eng_picture_mb2g8c" /> 
 								<a href={t('sma.standardLink')} download>Standards of Care for SMA  </a>
 							</div>
	 						<div> 
	 							<img src="http://res.cloudinary.com/dn5v5udu6/image/upload/curesma_logo_comwdd" />
	 							<a href = 'http://www.fsma.org' target="_blank">CURESMA  </a> 
	 						</div>
	 						<div> 
	 							<img src="http://res.cloudinary.com/dn5v5udu6/image/upload/mda_logo_ujflay" /> 
	 							<a href = 'https://www.mda.org/' target="_blank">Muscular Dystrophy Association  </a> 
	 						</div>
	 						<div> 
	 							<img src="http://res.cloudinary.com/dn5v5udu6/image/upload/SMAF_logo_mps3ss" /> 
	 							<a href = 'http://www.smafoundation.org/' target="_blank">SMA Foundation  </a>
	 						</div> 
	 						<div> 
	 							<img src="http://res.cloudinary.com/dn5v5udu6/image/upload/logos-treat-nmd-logo_j98bqd" />
	 							<a href = 'http://www.treat-nmd.eu/' target="_blank">TREAT-NMD Neuromuscular Network  </a>
	 						</div>            
	 						<div> 
	 							<img src="http://res.cloudinary.com/dn5v5udu6/image/upload/fsmaChina_logo_tocs03" /> 
	 							<a href = 'http://www.fsmachina.org/' target="_blank">FSMA-CHINA </a>                
	 						</div>                
	 						<div> 
	 							<img src="http://res.cloudinary.com/dn5v5udu6/image/upload/Meier_Advocacy_logo_msx5w8" /> 
	 							<a href = 'http://www.meier.org.cn/' target="_blank">Meier Advocacy & Support Center for SMA </a>                
	 						</div>                
	 						<div> 
	 							<img src="http://res.cloudinary.com/dn5v5udu6/image/upload/SMA_NewsToday_logo_mnalko" /> 
	 							<a href = 'https://smanewstoday.com/' target="_blank">SMA News Today </a> 
	 						</div> 
	 						<div> 
	 							<img src="newstoday.png" /> 
	 							<a href = 'https://www.smabma.org/' target="_blank">Become the Miracle Association Taiwan</a> 
	 						</div> 
	 					</div>
	 				</div>
	 				:displayItems
	 				
	 			}
 			</div>
 		)
 	}

	const showLeftLinks = items.map((item,index) => 
		 	<div class = 'sma-leftLinks pointer' onClick = {()=>changeCurrent(item,index)}>
		 		{index==current
		 			?<div><b>{item[getLanguage()].title}</b></div>
					:<div>{item[getLanguage()].title}</div>
		 		}
			</div>
		)

	const references = items[current][getLanguage()].references.map(reference =>
		<div >
			{reference}
		</div>
	)

	return (
	    <CloudinaryContext cloudName="dn5v5udu6">
	    <Header></Header>
		<div class = 'sma-color'>
			<div class = 'sma-first-sub'>
				<div class = 'sma'>
					<div class = 'sma-words'>
						<h1> {t('sma.smaTitle')}</h1>
						<div class = 'sma-first-links'>
							{console.log(items)}
							{showLinks}
							
						</div>
						
					</div>

				</div>
			</div>
		</div>
		<div class = 'sma-first-second'>
			<div class='stupid-scroll-container'>
				<div class='stupid-scroll'>SCROLL</div>
				<div class = 'stupid-scroll-line'></div>
			</div>
			<img src= './wave3.png' class='sma-second-image'></img>
		</div>
		<div class = 'sma-second'>
			<div class = 'sma-dots'>
				<img src = './about-dots.png' class ='sma-dots-image'></img>
			</div>
			<div class = 'sma-second-container'>
				<div class = 'sma-left-links-container'>
					{showLeftLinks}
				</div>
				<SMAItems cat = {items[current]} />
				<div class = 'sma-references'>
				{!further &&
					<div>
						<b>{t('sma.references')}</b>
						{references}
					</div>
				}
					<div class = 'sma-wave'>
						<img src = './ocean-wave.png' class ='sma-wave-image'></img>
					</div>
				</div>
			</div>
			{items[current].eng.title=='Characteristic Symptoms'
			?<div class = 'sma-third-container'>
				<div></div>
				<div>
				{getLanguage()=='eng'
				?
			 	 	<table class = 'sma-table'>
			 	 		<tr class ='table-first-row'>
			 	 			<th>Age of Onset</th>
			 	 			<th>Type</th>
			 	 			<th>Category</th>
			 	 			<th>Highest Motor Milestone Achieved</th>
			 	 			<th>Life Expectancy</th>
			 	 			<th>Characteristics</th>
			 	 		</tr>
			 	 		<tr>
			 	 			<td>Before birth (prenatal)</td>
			 	 			<td>0</td>
			 	 			<td>(Category not defined)</td>
			 	 			<td>Unable to achieve motor milestone </td>
			 	 			<td> &lt; 6 months </td>
			 	 			<td>
			 	 				<ul>
			 	 					<li>Severe weakness at birth</li>
			 	 					<li>Lack of muscle tone </li>
			 	 					<li>Facial paralysis</li>
			 	 					<li>Respiratory failure</li>
			 	 				</ul>
			 	 			</td>
			 	 		</tr>
			 	 		<tr>
			 	 			<td>0-6 months (infant)</td>
			 	 			<td>1</td>
			 	 			<td>Non-sitter</td>
			 	 			<td>Unable to sit unassisted </td>
			 	 			<td>2 years</td>
			 	 			<td>
			 	 				<ul>
			 	 					<li>Poor or no head contrli</li>
			 	 					<li>Bell-shaped chest</li>
			 	 					<li>Chewing and swallowing difficulties</li>
			 	 					<li>Breathing difficulties</li>
			 	 					<li>Tongue twitching (fasciculation)</li>
			 	 					<li>Poor muscle tone (hypotonia: floppy baby syndrome)</li>
			 	 					<li>‘Frog-leg’ posture when lying down </li>
			 	 				</ul>
			 	 			</td>
			 	 		</tr>
			 	 		<tr>
			 	 			<td>6-18 months  (intermediate)</td>
			 	 			<td>2</td>
			 	 			<td>Sitter</td>
			 	 			<td>Able to sit unassisted </td>
			 	 			<td>&gt; 2 years 70% still living at age 25 years </td>
			 	 			<td>
			 	 				<ul>
			 	 					<li>Muscle weakness</li>
			 	 					<li>Absent (areflexia) or reduced (hyporeflexia) reflexes</li>
			 	 					<li>Poor muscle tone (hypotonia)</li>
			 	 					<li>Hand tremor</li>
			 	 					<li>Children may develop spinal problems such as curvature of the spine (scliiosis) in severe cases weakness </li>
			 	 				</ul>
			 	 			</td>
			 	 		</tr>
			 	 		<tr>
			 	 			<td>&gt; 18 months (juvenile)</td>
			 	 			<td>3</td>
			 	 			<td>Walker</td>
			 	 			<td>Able to walk independently (although they may progressively lose this ability)  </td>
			 	 			<td>Normal</td>
			 	 			<td>
			 	 				<ul>
			 	 					<li>Muscle weakness is greater in the legs than the arms</li>
			 	 					<li>Hand tremor may occur </li>
			 	 				</ul>
			 	 			</td>
			 	 		</tr>
			 	 		<tr>
			 	 			<td>&gt;21 years (adult)</td>
			 	 			<td>4</td>
			 	 			<td>Adult walker</td>
			 	 			<td>All </td>
			 	 			<td>Normal</td>
			 	 			<td>
			 	 				<ul>
									<li>
									Physical symptoms are similar to type 3, with a gradual onset first noted in early adulthood 
			 	 					</li>
			 	 				</ul>
			 	 			</td>
			 	 		</tr>
			 	 	</table>
			 	 	:getLanguage() == 'tra'
					? 	
					<table class = 'sma-table'>
			 	 		<tr class ='table-first-row'>
			 	 			<th>發病年齡</th>
			 	 			<th>類型</th>
			 	 			<th>類別</th>
			 	 			<th>達成之最高活動里程碑</th>
			 	 			<th>預期壽命</th>
			 	 			<th>特徵 </th>
			 	 		</tr>
			 	 		<tr>
			 	 			<td>出生前(產前)</td>
			 	 			<td>0</td>
			 	 			<td>類別未定義</td>
			 	 			<td>無法達到活動里程碑</td>
			 	 			<td> &lt; 6 個月 </td>
			 	 			<td>
			 	 				<ul>
			 	 					<li>出生時嚴重虛弱</li>
			 	 					<li>缺乏肌肉張力</li>
			 	 					<li>臉部癱瘓</li>
			 	 					<li>呼吸衰竭</li>
			 	 				</ul>
			 	 			</td>
			 	 		</tr>
			 	 		<tr>
			 	 			<td>0-6個月(嬰兒)</td>
			 	 			<td>1</td>
			 	 			<td>無法坐立</td>
			 	 			<td>沒有協助則無法坐立 </td>
			 	 			<td>2 歲</td>
			 	 			<td>
			 	 				<ul>
			 	 					<li>頭部控制欠佳或缺乏</li>
			 	 					<li>胸部呈鐘形</li>
			 	 					<li>咀嚼與吞嚥困難</li>
			 	 					<li>呼吸困難</li>
			 	 					<li>舌頭抽搐（肌束顫搐)</li>
			 	 					<li>肌肉張力欠佳（肌張力低下：鬆軟嬰兒症候群）</li>
			 	 					<li>躺下呈現「青蛙腿」姿勢</li>
			 	 				</ul>
			 	 			</td>
			 	 		</tr>
			 	 		<tr>
			 	 			<td>6-18個（進階)</td>
			 	 			<td>2</td>
			 	 			<td>可坐立</td>
			 	 			<td>能夠獨自坐立</td>
			 	 			<td>&gt;  2 歲，70%患者在 25 歲仍生存 </td>
			 	 			<td>
			 	 				<ul>
			 	 					<li>肌肉虛弱</li>
			 	 					<li>•減少或缺乏反射（無或缺少反射）</li>
			 	 					<li>肌肉張力欠佳（低肌張力）</li>
			 	 					<li>手部震顫</li>
			 	 					<li>病童可能出現脊椎問題，例如嚴重虛弱的個案會出現脊柱側彎。 </li>
			 	 				</ul>
			 	 			</td>
			 	 		</tr>
			 	 		<tr>
			 	 			<td>&gt; 18個月(少年）</td>
			 	 			<td>3</td>
			 	 			<td>可行走</td>
			 	 			<td>能夠獨自行走（但可能逐漸失去步行能力） </td>
			 	 			<td>正常</td>
			 	 			<td>
			 	 				<ul>
			 	 					<li>雙腳肌肉比雙臂肌肉虛弱</li>
			 	 					<li>可能出現手震 </li>
			 	 				</ul>
			 	 			</td>
			 	 		</tr>
			 	 		<tr>
			 	 			<td>&gt;21歲（成人）</td>
			 	 			<td>4</td>
			 	 			<td>成年可行走</td>
			 	 			<td>全部 </td>
			 	 			<td>正常</td>
			 	 			<td>
			 	 				<ul>
									<li>
									症狀類似第三型患者。患者在成年首次出現症狀，症狀通常是慢慢地發生
			 	 					</li>
			 	 				</ul>
			 	 			</td>
			 	 		</tr>
			 	 	</table>
			 	 	:
			 	 	 <table class = 'sma-table'>
			 	 		<tr class ='table-first-row'>
			 	 			<th>发病年龄</th>
			 	 			<th>类型</th>
			 	 			<th>类别</th>
			 	 			<th>达成之最高活动里程碑</th>
			 	 			<th>预期寿命</th>
			 	 			<th>特徵 </th>
			 	 		</tr>
			 	 		<tr>
			 	 			<td>出生前(产前)</td>
			 	 			<td>0</td>
			 	 			<td>类别未定义</td>
			 	 			<td>无法达到活动里程碑</td>
			 	 			<td> &lt; 6 个月 </td>
			 	 			<td>
			 	 				<ul>
			 	 					<li>出生时严重虚弱</li>
			 	 					<li>缺乏肌肉张力</li>
			 	 					<li>脸部瘫痪</li>
			 	 					<li>呼吸衰竭</li>
			 	 				</ul>
			 	 			</td>
			 	 		</tr>
			 	 		<tr>
			 	 			<td>0-6个月(婴儿)</td>
			 	 			<td>1</td>
			 	 			<td>无法坐立</td>
			 	 			<td>没有协助则无法坐立 </td>
			 	 			<td>2 岁</td>
			 	 			<td>
			 	 				<ul>
			 	 					<li>头部控制欠佳或缺乏</li>
			 	 					<li>胸部呈钟形</li>
			 	 					<li>咀嚼与吞嚥困难</li>
			 	 					<li>呼吸困难</li>
			 	 					<li>舌头抽搐（肌束颤搐)</li>
			 	 					<li>肌肉张力欠佳（肌张力低下：松软婴儿症候群）</li>
			 	 					<li>躺下呈现「青蛙腿」姿势</li>
			 	 				</ul>
			 	 			</td>
			 	 		</tr>
			 	 		<tr>
			 	 			<td>6-18个月（进阶)</td>
			 	 			<td>2</td>
			 	 			<td>可坐立</td>
			 	 			<td>能够独自坐立</td>
			 	 			<td>>  2 岁，70%患者在 25 岁仍生存 </td>
			 	 			<td>
			 	 				<ul>
			 	 					<li>肌肉虚弱</li>
			 	 					<li>•减少或缺乏反射（无或缺少反射）</li>
			 	 					<li>肌肉张力欠佳（低肌张力）</li>
			 	 					<li>手部震颤</li>
			 	 					<li>病童可能出现脊椎问题，例如严重虚弱的个案会出现脊柱侧弯。 </li>
			 	 				</ul>
			 	 			</td>
			 	 		</tr>
			 	 		<tr>
			 	 			<td>&gt; 18个月 (少年）</td>
			 	 			<td>3</td>
			 	 			<td>可行走</td>
			 	 			<td>能够独自行走（但可能逐渐失去步行能力） </td>
			 	 			<td>正常</td>
			 	 			<td>
			 	 				<ul>
			 	 					<li>双脚肌肉比双臂肌肉虚弱</li>
			 	 					<li>可能出现手震 </li>
			 	 				</ul>
			 	 			</td>
			 	 		</tr>
			 	 		<tr>
			 	 			<td>&gt;21岁（成人）</td>
			 	 			<td>4</td>
			 	 			<td>成年可行走</td>
			 	 			<td>全部 </td>
			 	 			<td>正常</td>
			 	 			<td>
			 	 				<ul>
									<li>
									症状类似第叁型患者。患者在成年首次出现症状，症状通常是慢慢地发生
			 	 					</li>
			 	 				</ul
>on			 	 			</td>
			 	 		</tr>
			 	 	</table>
			 	 }
			 	 </div>
			 </div>
			:<div></div>
			}
		</div>
						<Contact color='yellow'/>
				<Footer />
	</CloudinaryContext>
	);
}

export default translate(SMA);
